.fullContainer {
    background-color: var(--our-projects-bg);
    transition: all 500ms ease-out;
}

.previewVideo {
    position: fixed;
    opacity: 0.2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -20;
    object-fit: cover;
}

.ourProjectsText {
    display: block;
    padding-top: 35px;
    font-size: 48px;
    color: var(--our-projects-title-color);
    text-align: center;
    font-weight: 500;
}

.mapBlock {
    width: 100%;
    height: 500px;
    background-color: #551d1d;
}

.gallery {
    margin: 15px auto 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* делаем нормальной последнюю строчку с проектами */
.gallery:after {
    flex-basis: calc((100% / 3) - 10px);
    content: "";
}

.notFound {
    font-family: "Nunito", sans-serif;
    font-size: 30px;
    font-weight: 400;
    color: var(--text-color);
}


.showMore {
    display: block;
    font-size: 17px;
    font-family: "Noah Head", sans-serif;
    font-weight: 400;
    border: 2px #A7BCDB solid;
    cursor: pointer;
    height: 50px;
    text-align: center;
    width: 100%;
    border-radius: 4px;
    background: none;
    transition: background 500ms ease;
}

.showMore:hover {
    background: #f3f8ff;
}

@media (max-width: 1040px) {
    .ourProjectsText {
        font-size: 34px;
    }
}

@media (max-width: 730px) {
    .ourProjectsText {
        font-size: 30px;
    }
}

@media (max-width: 500px) {
    .ourProjectsText {
        font-size: 26px;
    }
}



