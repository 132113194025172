.sliderBlock {
    display: flex;
    align-items: center;
    /*overflow: hidden;*/
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #242E41;
}

.photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.photoBlock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all 2.5s ease-in-out;
}

.photoBlockPrev {
    opacity: 0;
    /*transform: translateX(-100%);*/
}

.photoBlockNext {
    opacity: 0;
    /*transform: translateX(100%);*/
}

