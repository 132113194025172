
.lineBox {
    display: flex;
    align-items: flex-end;
    /*width: calc(100% / 6 - 6px);*/
    z-index: 10;
    height: 100%;
}

.line {
    display: flex;
    width: 100%;
    height: 3px;
    z-index: 10;

    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.7);
    color: rgba(255, 255, 255, 0.8);
    border: none;
    margin-bottom: 5px;
}

.lineBox:hover .line{
    color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 1);
}