.container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    font-family: "Nunito", sans-serif;
}

.showFilters button {
    color: var(--our-projects-text-color);
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    transition: all 500ms ease-out;
}

.showFilters {
    display: flex;
    align-items: center;
}

.showFilters button {
    outline: none;
}
.showFilters label {
    display: inline-flex;
    height: auto;
    cursor: pointer;
}

.showFilters .arrow {
    transition: rotate 700ms ease;
}

.arrow * {
    transition: all 400ms ease-in-out;
    stroke: var(--our-projects-text-color);
}

.showFilters .arrowOpened {
    rotate: -180deg;
}

.filtersClosed {
    width: 100%;
    max-height: 0;
    /*background: linear-gradient(180deg, rgba(35, 88, 164, 0.14) 0%, rgba(255, 255, 255, 0.035) 6.77%, rgba(255, 255, 255, 0.074375) 95.31%, rgba(35, 88, 164, 0.14) 100%), #FFFFFF;*/
    box-shadow: var(--our-projects-filter-opend-shadow);
    background: var(--our-projects-filter-opend-bg);
    
    /*надо решить, что использвоать*/
    padding: 0;
    overflow: hidden;
    transition: all 700ms ease, width 0ms;
}

.filtersOpened{
    padding: 50px 0;
    max-height: 500px;
    /*box-shadow: inset 0 8px 20px rgba(35, 88, 164, 0.2), inset 0 -8px 20px rgba(35, 88, 164, 0.2);*/

}

section hr {
    margin-top: 15px;
    color: var(--our-projects-line-color); /* Цвет линии для остальных браузеров */
    background-color: var(--our-projects-line-color); /* Цвет линии для браузера Firefox и Opera */
    height: 1px; /* Толщина линии */
    opacity: 30%;
    border: none;
}

.types {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.typesOfWorkPlace {
    gap: 15px;
    display: flex;
    flex-wrap: wrap;
    margin-right: 20%;
    margin-bottom: 20px;
    opacity: 1;
    transition: all 300ms ease-in-out;
}

.filterButtonsClosed {
    opacity: 0;
}

.numberOfRooms {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    color: var(--filter-button-color);
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 20px;
    transition: all 300ms ease-in-out;
}


.filtersAndIm {
    display: flex;
    position: relative;
    justify-content: space-between;
}

.logoU {
    position: absolute;
    right: 5%;
}

.logoU * {
    fill: rgba(255, 255, 255, 0.2);
}

.slider{
    margin-top: 15px;
}

.typography {
    color: var(--filter-button-color);
}

.sliderBox {
    padding: 0 12px;
}

.slider p {
    font-size: 17px;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
}

.sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    flex-wrap: wrap;
}

.sortBlock {
    column-gap: 15px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
}

.sortByText {
    font-size: 17px;
    font-weight: 300;
    margin-right: 20px;
    white-space: nowrap;
    transition: all 500ms ease-out;
    color: var(--our-projects-text-color);
}

.mapAndSearchBlock {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    gap: 15px;
    margin-bottom: 20px;
}

.mapButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    white-space: nowrap;
    height: 40px;
    background-color: #25427e;
    color: white;
    font-size: 16px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    transition: all ease-out 500ms;
    font-weight: 300;
}

.mapButton span {
    display: block;
    margin: 0 20px;
}


.mapButton:hover, .mapButtonHovered {
    background-color: #122E65;
    color: white !important;
}

.search {
    font-size: 17px;
    border-radius: 8px;
    height: 40px;
    border: none;
    padding: 6px 6px 6px 30px;
    outline: none;
    color: var(--text-color);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    background: var(--our-projects-sortButton-bg-url) no-repeat 7px 4px, var(--our-projects-sortButton-bg);
    transition: all ease-out 500ms;
    
}

/*.search::after {*/
/*    content: "asdf"; !* Юникод или символ иконки (Font Awesome, например) *!*/
/*    position: absolute;*/
/*    height: 10px;*/
/*    width: 10px;*/
/*    top: 50%;*/
/*    right: 10px; !* Отступ от правого края *!*/
/*    transform: translateY(-50%);*/
/*    color: #999; !* Цвет иконки *!*/
/*    pointer-events: none; !* Чтобы иконка не перехватывала события *!*/
/*}*/

.searchList {
    display: flex;
    flex-direction: column;
    z-index: 18;
    border-radius: 4px;
    position: absolute;
    overflow: auto;
    max-height: 300px;
    width: 130%;
    max-width: 350px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(14px);
    top: calc(15px + 100%);
    right: 0;
}

.searchItem {
    font-family: "Nunito", sans-serif;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    justify-content: space-between;
}
.searchList a {
    color: black;
    text-decoration: none;
    border-bottom: rgba(0, 0, 0, 0.12) 1px solid;
    transition: all 200ms ease-in;
}

.searchList a:hover {
    background: rgba(255, 255, 255, 0.4);
}

.searchItem a:nth-last-child(1) {
    border-bottom: none;
}

.search:before {
    height: 10px;
    width: 10px;
    background: #000;
}

@media (max-width: 1040px) {
    .showFilters button {
        font-size: 16px;
    }

}

@media (max-width: 750px) {
    
    .types {
        flex-direction: column;
    }
    .showFilters button {
        font-size: 14px;
    }
    section hr {
        margin-top: 10px;
    }
    
    .filtersOpened {
        padding: 30px 0;
    }

    .logoU {
        display: none;
    }

    .types {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .typesOfWorkPlace {
        gap: 10px;
        max-width: 100%;
        margin-right: 0;
    }
    
    .numberOfRooms {
        gap: 10px;
        max-width: 100%;
        margin-right: 0;
    }

    .slider {
        margin-top: 10px;
    }

    .slider p {
        font-size: 14px;
    }

    /* переносим блок поиска на некст строку */
    .sort {
        flex-direction: column;
        align-items: start;
    }

    .sortBlock {
        flex-wrap: wrap;
    }

    .sortByText {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .search {
        font-size: 15px;
    }

    .searchList {
        width: 100%;
    }

    .sortBlock {
        margin-bottom: 0px;
    }

 .mapAndSearchBlock {
      margin-bottom: 10px;
  }

    .mapAndSearchBlock {
        flex-wrap: wrap;
    }

}

@media (max-width: 630px) {
    .searchList {
        backdrop-filter: none;
        background: var(--our-projects-search-input-bg);
        
    }
    
    .searchItem {
        color: var(--text-color);
    }
}


