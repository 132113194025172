.container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    font-family: "Noah Text", sans-serif;
}

.title {
    display: block;
    text-align: center;
    margin-top: 25px;
    font-size: 44px;
    font-family: "Noah Text", sans-serif;
    font-weight: 500;
}

.blogBlock {
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}




.blogHeader {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 30px;
    overflow: hidden;
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.logo {
    width: 70%;
    height: 70%;
}


@media (max-width: 1310px) {
    .container {
        max-width: 1010px;
    }
}

@media (max-width: 990px) {
    .container {
        max-width: 680px;
    }

    .title {
        font-size: 36px;
    }
}

@media (max-width: 670px) {
    .container {
        max-width: 500px;
    }
    .blogBlock {
        justify-content: center;
    }
}

