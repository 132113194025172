.fullContainer {
    background-color: var(--our-projects-bg);
    transition: all 500ms ease-out;
}

.container {
    position: relative;
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1340px;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    transition: all 500ms ease-out;
    /*background-color:  var(--our-projects-bg);*/
}

.mapBlock {
    position: relative;
    width: 100%;
    height: 0;
    opacity: 0;
    border-radius: 8px;
    overflow: hidden;
    transition: all 500ms ease-out;
}

.map {
    width: 100%;
    height: 600px;
}

.mapBlockOpened {
    height: 600px;
    opacity: 1;
}

.mapButtonsBlock {
    position: absolute;
    display: flex;
    z-index: 95;
    top: 5px;
    left: 5px;
    overflow: hidden;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}

.mapButton {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    z-index: 200;
    height: 50px;
    background-color: white;
    border-right: 1px solid #eeeeee;
    transition: all 300ms ease-in-out;
}

.mapButton:hover, .mapButtonHovered {
    background-color: #eeeeee;
}

.mapButton:nth-last-child(1) {
    border-right: none;
}

/*.mapButton[data-title]:hover::after {*/
/*    content: "asdfasdf";*/
/*    position: absolute;*/
/*    top: 100%;*/
/*    left: 0;*/
/*    z-index: 9999999;*/
/*    background-color: aquamarine;*/
/*    width: 250px;*/
/*}*/

.point {
    width: 27px;
    height: 27px;
}
