.figuresBlock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.y {
    position: absolute;
    top: -40px;
    left: 15px;
    z-index: 0;
    /*animation: infinite fly 2s ease-in-out alternate;*/
}

.y * {
    width: 200px;
    height: auto;
}

.star {
    position: absolute;
    bottom: -35px;
    right: -30px;
    z-index: 0;
    animation: infinite blink 1.5s ease-in-out alternate;
}

.star * {
    width: 100px;
    height: auto;
}

.p {
    position: absolute;
    top: -30px;
    left: 35px;
    z-index: 0;
    /*animation: infinite fly3 1.5s ease-in-out alternate;*/
}

.p * {
    width: 150px;
    height: auto;
}

.pDark, .uDark, .kDark, .yDark {
    display: var(--figure-dark-display);
}

.pLight, .uLight, .kLight, .yLight {
    display: var(--figure-light-display);
}

.k {
    position: absolute;
    bottom: -30px;
    left: -15px;
    z-index: 0;
    /*animation: infinite fly 2s ease-in-out alternate-reverse;*/
}

.k * {
    width: 200px;
    height: auto;
}

.u {
    position: absolute;
    right: 0;
    top: -35px;
    z-index: 0;
    animation: infinite blink 1.5s ease-in-out alternate-reverse;
}

.u * {
    width: 200px;
    height: auto;
}


@keyframes fly {
    0% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(10px);
    }
}

@keyframes fly2 {
    0% {
        transform: translateY(-10px) translateX(10px);
    }
    100% {
        transform: translateY(10px) translateX(-10px);
    }
}

@keyframes blink {
    0% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}


@keyframes fly3 {
    0% {
        transform: translateY(10px) translateX(10px);
    }
    100% {
        transform: translateY(-10px) translateX(-10px);
    }
}

@media (max-width: 730px) {
    .y * {
        height: 150px;
        width: 150px;
    }
    
    .y {
        top: -20px;
    }
    
    .p * {
        height: 150px;
        width: 150px;
    }
    
    .p {
        top: -20px;
    }
}