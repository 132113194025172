.skeletonBlock {
    position: relative;
    width: calc((100% / 3) - 10px);
    height: 0;
    padding-bottom: 23%;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: inset 0 0 12px 7px rgba(0, 0, 0, 0.1);
}
.skeleton {
    position: absolute;
    top:0;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 1040px) {
    .skeletonBlock {
        width: calc((100% / 2) - 7px);
        padding-bottom: 35%;
        margin-bottom: 14px;
    }
}

@media (max-width: 500px) {
    .skeletonBlock {
        width: 100%;
        padding-bottom: 65%;
    }
}