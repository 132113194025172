@import url("fonts/Noah/stylesheet.css");
@import 'react-notifications/lib/notifications.css';
* {
    font-family: "Nunito", sans-serif !important;
}

html[data-theme='dark'] {
    --text-color: #e5e5e5;
    
    --preview-bg: black;

    --navbar-background-color: rgba(0, 0, 0, 0.72);
    --navbar-navbar-buttons-color: #A0A0A0;
    --navbar-navbar-buttons-active-color: white;
    --navbar-buttons-phone-bg: black;
    --navbar-buttons-burger-color: white;
    --navbar-buttons-social-hover: white;

    --about-us-bg: #050505;
    --about-us-border-color: #263f6f;
    --about-us-stroke-color: #35599d;
    --about-us-back-line-stroke-color: #3076ff;
    --about-us-text-color: #dbdbdb;
    
    --our-block-first-third-background:  linear-gradient(134deg, rgba(255, 255, 255, 0.12) 0%, rgba(22, 22, 22, 0.22) 100%);
    --our-block-second-forth-background:  linear-gradient(134deg, rgba(255, 255, 255, 0.12) 0%, rgba(22, 22, 22, 0.22) 100%);
    --our-block-box-shadow: none;
    --our-block-border-color: 1px solid rgb(45, 45, 45);

    --other-projects-bg: #050505;
    --other-projects-button-bg: #121212;
    --other-projects-button-bg-hover: #25427E;
    --other-projects-button-color-hover: white;
    --other-projects-button-shadow: none;
    --other-projects-button-color: white;
    
    --connect-us-bg: #050505;
    --connect-us-block-bg: rgba(0, 0, 0, 0.69);

    --footer-bg: #050505;
    --footer-border-top: 1px #212735 solid;
    --footer-box-shadow: none;

    --toggle-color: #050505;
    --toggle-position: calc(100%);
    --toggle-transform: translateX(25px);
    --toggle-sun-icon-display: none;
    --toggle-moon-icon-display: block;
    
    --how-we-work-light-display: none;
    --how-we-work-light-display-flex: none;
    --how-we-work-dark-display: block;
    --how-we-work-dark-display-flex: flex;
    --how-we-work-bg: #050505;
    --how-we-work-title: #dbdbdb;
    --how-we-work-glass-block-bg: none;
    --how-we-work-glass-block-border: none;
    --how-we-work-glass-block-shadow: none;
    --how-we-work-glass-block-filter: none;
    --how-we-work-glass-block-text-color: #cbcbcb;
    --how-we-work-glass-block-text-link-color-hover: #658cbb;
    --how-we-work-content-row-padding: 0 0;
    --how-we-work-step-title-translateY: 15px;
    --how-we-work-step-title-margin-bottom: 25px;
    --how-we-work-step-title-color-right: #3160a4;
    --how-we-work-step-title-color-left: #52B26E;

    --our-projects-title-color: white;
    --our-projects-text-color: white;
    --our-projects-bg: black;
    --our-projects-line-color: #a0c1f1;
    --our-projects-filter-opend-bg: linear-gradient(180deg, #031939 0%, #050505 100%);
    --our-projects-filter-opend-shadow: none;
    --our-projects-sortButton-bg: #1e1e1f;
    --our-projects-sortButton-bg-url: url(../../image/icons/searchWhite.svg);
    --our-projects-search-input-bg: rgb(59, 59, 63);
    --our-projects-mapButton-bg: #3B3B3F;
    --our-projects-sortButton-selected-bg: #3b3b3f;

    --project-bg: black;
    --project-text-color: #cbcbcb;

    --time-line-margin-left: 0;
    
    --about-company-bg-color: #141414;
    --about-company-bg-color-hover: #202020;
    
    --mini-project-shadow: rgba(255, 255, 255, 0.25);
    
    --figure-dark-display: block;
    --figure-light-display: none;

    --filter-button-bg: rgba(255, 255, 255, 0.05);
    --filter-button-color: white;

    --contact-icon-color: #DCDCDC;

}
html[data-theme='light'] {
    --text-color: black;

    --preview-bg: white;
    
    --navbar-background-color: #FFF;
    --navbar-navbar-buttons-color: #848484;
    --navbar-navbar-buttons-active-color: black;
    --navbar-buttons-phone-bg: white;
    --navbar-buttons-burger-color: black;
    --navbar-buttons-social-hover: black;

    --about-us-bg: rgba(238, 245, 255, 0.62);
    --about-us-border-color: #c9e0ff;
    --about-us-stroke-color: #7eabc4;
    --about-us-back-line-stroke-color: none;
    --about-us-text-color: black;
    
    --our-block-first-third-background: linear-gradient(136deg, rgba(94, 202, 124, 0.50) 0.01%, rgba(31, 69, 126, 0.93) 100%);
    --our-block-second-forth-background: linear-gradient(134deg, rgba(31, 69, 126, 0.50) 0%, rgba(94, 202, 124, 0.93) 100%);
    --our-block-box-shadow: 0 0 14px 4px rgba(108, 153, 241, 0.25) !important;
    --our-block-border-color: none;

    --other-projects-bg: white;
    --other-projects-button-bg: linear-gradient(134deg, rgba(192, 192, 192, 0.13) 0%, rgba(178, 171, 171, 0.13) 0.01%, rgba(29, 29, 29, 0.04) 100%);
    --other-projects-button-bg-hover: #25427E;
    --other-projects-button-color-hover: white;
    --other-projects-button-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.25);
    --other-projects-button-color: black;
    
    --connect-us-bg: white;
    --connect-us-block-bg: rgba(26, 26, 26, 0.27);
    
    --footer-bg: rgb(242, 244, 249);
    --footer-border-top: none;
    --footer-box-shadow: inset 0 4px 14px rgba(35, 88, 164, 0.15);
    
    --toggle-color: white;
    --toggle-position: 2px;
    --toggle-transform: translateX(0);
    --toggle-sun-icon-display: block;
    --toggle-moon-icon-display: none;

    --how-we-work-light-display: block;
    --how-we-work-light-display-flex: flex;
    --how-we-work-dark-display: none;
    --how-we-work-dark-display-flex: none;
    --how-we-work-bg: white;
    --how-we-work-title: black;
    --how-we-work-glass-block-bg: linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 46.67%, rgba(66, 66, 66, 0.07) 100%);
    --how-we-work-glass-block-border: 1px solid rgba(255, 255, 255, 0.30);
    --how-we-work-glass-block-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.13);
    --how-we-work-glass-block-filter: blur(16px);
    --how-we-work-glass-block-text-color: #232B3B;
    --how-we-work-glass-block-text-link-bg: #dae9ff;
    --how-we-work-glass-block-text-link-bg-hover: #435d8e;
    --how-we-work-glass-block-text-link-color-hover: #ffffff;
    --how-we-work-content-row-padding: 0;
    --how-we-work-step-title-translateY: -20px;
    --how-we-work-step-title-margin-bottom: 0;
    --how-we-work-step-title-color-right: #00327A;
    --how-we-work-step-title-color-left: #1E6B4F;
    
    --our-projects-title-color: black;
    --our-projects-text-color: black;
    --our-projects-bg: white;
    --our-projects-line-color: rgba(35, 88, 164, 0.2);
    --our-projects-filter-opend-bg: #F0F1F2;
    --our-projects-filter-opend-shadow: 0 25px 15px -16px rgba(0, 0, 0, 0.25) inset, 0 -27px 15px -19px rgba(0, 0, 0, 0.25) inset;
    --our-projects-sortButton-bg: white;
    --our-projects-sortButton-bg-url: url(../../image/icons/searchBlack.svg);
    --our-projects-search-input-bg: rgb(209, 220, 235);
    --our-projects-mapButton-bg: #D3DEED;
    --our-projects-sortButton-selected-bg: #D3DEED;

    --project-bg: white;
    --project-text-color: black;

    --time-line-margin-left: 15px;

    --about-company-bg-color: #f6f9ff;
    --about-company-bg-color-hover: #eaf1ff;

    --mini-project-shadow: rgba(0, 0, 0, 0.05);

    --figure-dark-display: none;
    --figure-light-display: block;

    --filter-button-bg: white;
    --filter-button-color: black;
    
    --contact-icon-color: black;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing:border-box;
    scroll-behavior:smooth;
    -webkit-tap-highlight-color: transparent;
}

.previewWhiteSpace {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--preview-bg);
    z-index: 50;
    -webkit-animation-fill-mode: forwards !important; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards !important;    /* FF 5+ */
    -o-animation-fill-mode: forwards !important;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards !important;     /* IE 10+ */
    animation-fill-mode: forwards !important;         /* When the spec is finished */
    animation: fon ease-in-out 2000ms;
}

.previewWhiteSpace svg {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation-fill-mode: forwards !important; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards !important;    /* FF 5+ */
    -o-animation-fill-mode: forwards !important;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards !important;     /* IE 10+ */
    animation-fill-mode: forwards !important;         /* When the spec is finished */
    animation: title ease-in-out 800ms;
}

.previewWhiteSpace span {
    display: inline-block;
    opacity: 0;
    filter: blur(10px);
}

.previewWhiteSpace span:nth-child(1) {
    animation: fade-in 0.5s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
@keyframes fade-in {
    100% {
        opacity: 1;
        filter: blur(0);
    }
}

@keyframes fon {
    0% {
        display: block;
        opacity: 1;
    }

    30% {
        opacity: 1;
    }

    100% {
        z-index: 0;
        opacity: 0;
        display: none;
    }

}

@keyframes title {
    0% {
       opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}



img {
}

.anchor {
    box-sizing: content-box;
    position: fixed;
    width: 60px;
    padding: 12px 4px;
    border-radius: 5px;
    height: auto;
    bottom: 30px;
    right: 50px;
    opacity: 0;
    cursor: pointer;
    z-index: 20;
    transition: all 200ms ease-in;
}

.notification {
    box-sizing: content-box;
    position: fixed;
    bottom: 30px;
    right: 50px;
    cursor: pointer;
    z-index: 200;
    transition: all 200ms ease-in;
}

.active {
    opacity: 1;
}

/*.previewVideo {*/
/*    position: fixed;*/
/*    opacity: 0.3;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*    z-index: -20;*/
/*    object-fit: cover;*/
/*}*/

/*@media (max-width: 730px) {*/
/*    .previewVideo {*/
/*        opacity: 0.4;*/

/*    }*/
/*}*/

@media (max-width: 500px) {
    .anchor {
        width: 50px;
        height: auto;
        right: 10px;
        bottom: 20px;
    }
}

.notification-success {
    border-radius: 5px;
    background-color: #13b140; /* Здесь вы можете установить желаемый цвет фона */
    color: white; /* Здесь вы можете установить желаемый цвет текста */
}

.notification {
    right: 10px;
}

