.fullContainer {
    transition: all 500ms ease-out;
    background-color: var(--connect-us-bg);

}

.container {
    margin: 0 auto;
    padding: 50px 20px 30px 20px;
    max-width: 1340px;
    font-family: "Noah Head", sans-serif;
    font-size: 16px;

}

.contactUs {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
}

.rect {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    animation: miniRect alternate infinite 2s linear;
}

.bg {
    display: flex;
    /*filter: brightness(1.8);*/
    width: 100%;
    /*background-size: cover !important;!* растягиваем на фулл картинку *!*/
    /*background: url('../../image/preview/contactUs.png') no-repeat center center;*/
}

.img {
    position: absolute;
    /*filter: brightness(1);*/
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blocks {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    margin: 70px 100px 50px 100px;
}

.connectBlock {
    position: relative;
    padding: 45px 80px;
    max-width: 650px;
    width: 60%;
    flex-shrink: 0;
    height: 600px;
    border-radius: 18px;
    /*border-top: 1px solid rgba(115, 137, 191, 0.66);*/
    /*border-left: 1px solid rgba(115, 137, 191, 0.66);*/
    backdrop-filter: blur(25px);
    background: var(--connect-us-block-bg);
    box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0.25);
    transition: all 500ms ease-out;
}

.statBlocks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.statBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 30px 20px 30px 20px;
    width: 100%;
    height: 30%;
    border-radius: 18px;
    /*border-top: 1px solid rgba(115, 137, 191, 0.66);*/
    /*border-left: 1px solid rgba(115, 137, 191, 0.66);*/
    /*border-top: 1px solid rgba(227, 234, 255, 0.66);*/
    /*border-left: 1px solid rgba(227, 234, 255, 0.66);*/
    backdrop-filter: blur(25px);
    background: var(--connect-us-block-bg);
    box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0.25);
    transition: all 500ms ease-out;
}


.statBlockTitle {
    color: white;
    font-size: 56px;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    opacity: 0;
    transition: 700ms all ease-out 200ms;
}

.statBlockTitleHovered {
    opacity: 1;
}

.statBlockText {
    color: white;
    font-size: 24px;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    transform: translateX(60px);
    transition: 700ms all ease-out 200ms;
    opacity: 0;
}

.statBlockTextHovered {
    opacity: 1;
    transform: translateX(0);
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
}

.logo {
    width: 70px;
    height: 70px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: start;
    font-size: 36px;
    font-weight: 100;
    color: white;
    font-family: "Nunito", sans-serif;;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.underTitleBlock {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.connectText  {
    width: 50%;
    font-family: "Nunito", sans-serif;
    color: white;
    font-weight: 100;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.changeConnectBlock {
    cursor: pointer;
    position: relative;
    width: 130px;
    height: 35px;
    border: rgba(201, 201, 201, 0.59) 1px solid;
    border-radius: 5px;
}

.toggle {
    position: absolute;
    top: 0;
    border-radius: 5px;
    filter: blur(0.5px);
    width: 33px;
    height: 33px;
    z-index: 2;
    transition: all 500ms ease-in-out;
}

.toggleIconBlock {
    position: absolute;
    top: 0;
    width: 33px;
    height: 33px;
    z-index: 4;
    transition: all 500ms ease-in-out;
}

.toggleIconBlockMessage {
    left: calc(100% - 33px);
}

.toggleIconBlockPhone{
    left: 0;
}

.toggleMessage {
    border: 1px solid rgba(0, 87, 255, 0.90);
    background: rgba(85, 123, 255, 0.26);
    left: calc(100% - 33px);
}

.togglePhone {
    border: 1px solid rgba(16, 187, 0, 0.9);
    background: rgba(89, 255, 85, 0.26);
    left: 0;
}

.toggleTextBlock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: white;
    font-family: "Nunito", sans-serif;
    font-weight: 100;
    z-index: 1;
}

.toggleText {
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    }

.toggleTextMessage {
    right: 10px;
}

.toggleTextPhone {
    left: 10px;
}

.toggleTextBlockPhone .toggleTextMessage {
    color: white;
    transition: 700ms all 300ms ease-in-out
}

.toggleTextBlockPhone .toggleTextPhone {
    color: transparent;
    transition: all 300ms ease-in-out
}

.toggleTextBlockMessage .toggleTextMessage {
    color: transparent;
    transition: all 300ms ease-in-out
}

.toggleTextBlockMessage .toggleTextPhone {
    color: white;
    transition: 700ms all 300ms ease-in-out
}

.toggleTextBlockPhone .toggleTextPhone {
    float: right;
}

.arrowsBlock {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 10px;
    left: 30%;
    transition: all 300ms ease-in-out, opacity 20ms ease-in-out;
}

.reverse {
    left: 70%;
    transform: translateY(-50%) translateX(-100%);
    transition: all 300ms ease-in-out
}

.arrowsBlock .arrow1Right, .arrowsBlock .arrow2Right {
    opacity: 1;
    transition: 1s opacity 200ms ease-in-out;
}

.arrowsBlock .arrow1Left, .arrowsBlock .arrow2Left {
    opacity: 0;
    transition: opacity 20ms ease-in-out;
}

.reverse .arrow1Right, .reverse .arrow2Right {
    opacity: 0;
    transition: opacity 20ms ease-in-out;
}

.reverse .arrow1Left, .reverse .arrow2Left {
    opacity: 1;
    transition: 1s opacity 200ms ease-in-out;
}

.arrow {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.arrowsBlock .arrow1Right {
    animation: 1s blink infinite 1s alternate ease-in-out;
}

.arrowsBlock .arrow2Right {
    animation: 1s blink infinite 2s alternate ease-in-out;
}

.reverse .arrow1Right, .reverse .arrow2Right {
    animation: none;
}

.reverse .arrow1Left {
    animation: 1s blink infinite 1s alternate ease-in-out !important;
}

.reverse .arrow2Left {
    animation: 1s blink infinite 2s alternate ease-in-out !important;
}

.arrowsBlock .arrow1Left, .reverse .arrow2Left {
    animation: none;
}

.arrow1Left, .arrow1Right {
    left: 0;
    
}

.arrow2Left, .arrow2Right {
    right: 0;
}

.toggleIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
}

@keyframes blink {
    from {
        opacity: 1;
    }
    
    to {
        opacity: 0.5;
    }
}



.input:first-child {
    margin-top: 40px;
}

.hidden {
    display: none;
}

.input {
    font-family: "Nunito", sans-serif;
    width: 100%;
    color: white;
    margin-top: 15px;
    padding: 0 0 0 20px;
    font-weight: 100;
    font-size: 15px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #b2b2b2;
    outline: none;
    background: rgba(26, 26, 26, 0.27);
}

.inputErrorMessage {
    font-family: "Nunito", sans-serif;
    height: 15px;
    color: #ff5757;
    padding-top: 2px;
    font-weight: 500;
    padding-left: 2px;
    font-size: 15px;
}

.input::-webkit-input-placeholder {
    color: #D9D9D9;
}

/*Change text in autofill textbox*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

.checkbox {
    margin-top: 15px;
    width: 100%;
    font-size: 16px;
}


.policyLink {
    color: #a6ccff;
}

.policyLink:hover {
    color: #7eb5ff;
    transition: all 200ms ease-out;
}

/* скрыли стандартный чекбокс */
.checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin-right: 15px;
}

/* выровняли */
.checkbox input+label {
    font-family: "Nunito", sans-serif;
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    color: white;
    font-weight: 100;
}

/* отрисовываем новую иконку */
.checkbox input+label::before {
    display: inline-block;
    cursor: pointer;
    content: '';
    width: 23px;
    height: 23px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-right: 15px;
}

/* если нажали на чекбокс */
.checkbox input:checked+label::before {
    /* Подключение SVG */
    background: url("../../image/check2.svg") no-repeat center center;
}

.subButtonBox {
    display: flex;
    margin-top: 15px;
    width: 100%;
    justify-content: right;
}

.submitButton {
    cursor: pointer;
    border: #c9c9c9 1px solid;
    /*width: calc(100% / 2.5);*/
    /*height: 50px;*/
    color: white;
    font-weight: 300;
    padding: 10px 40px;
    font-family: "Nunito", sans-serif;
    background: none;
    font-size: 18px;
    border-radius: 6px;
    transition: all 200ms ease-in;
}

.submitButton:hover {
    background-color: rgba(233, 244, 255, 0.25);
}

.noticesBlock {
    z-index: 6;
    position: fixed;
    max-width: 250px;
    width: 100%;
    right: 15px;
    bottom: 15px;
    /*background: rgba(0, 0, 0, 0.16);*/
}

.notice {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: rgba(97, 197, 125, 0.88);
    margin-top: 10px;
    border: #4ca164 solid 1px;
    border-radius: 6px;
    backdrop-filter: blur(4px);
    transition: all 300ms ease-in-out;
    animation: 3000ms notice ease-in-out 1200ms, notice2 ease-in-out 800ms;
}

.notice span {
    font-size: 20px;
    font-weight: 400;
    color: white;
}

@keyframes notice {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes notice2 {
    0% {
        margin-left: 120%;
    }
    100% {
        margin-left: 0;
    }
}


@keyframes miniRect {
    from {
        top: 100px;
        right: -100px;
    }
    to {
        top: 30px;
        right: -30px;
    }
}

@media (max-width: 1200px) {
    .blocks {
        width: 100%;
        margin: 50px 70px 40px 70px;
    }
    .connectBlock {
        width: 600px;
    }

    .content {

    }

    .title {
        font-size: 32px;
    }

    .logo {
        width: 60px;
        height: 60px;
    }

    .connectText {
        font-size: 14px;
        margin-top: 15px;
    }

    .input:first-child {
        margin-top: 30px;
    }

    .input {
        margin-top: 10px;
        font-size: 14px;
        height: 45px;
    }

    .inputErrorMessage {
        font-size: 12px;
    }

    .checkbox {
        font-size: 14px;
    }

    .checkbox input+label::before {
        width: 20px;
        height: 20px;
    }

    .subButtonBox {
        margin-top: 10px;
    }

    .submitButton {
        font-size: 15px;
    }
}
@media (max-width: 1110px) {
    .blocks {
        flex-direction: column-reverse;
        align-items: center;
        margin: 30px 30px 30px 30px;
    }
    .connectBlock {
        margin-right: 0;
        width: 100%;
    }
    
    .statBlocks {
        flex-direction: row;
        justify-content: space-between;
    }
    
    .statBlock {
        width: calc(33% - 20px);
        height: 100%;
    }
    
    .statBlockTitle {
        font-size: 50px;
    }
    
    .statBlockText {
        font-size: 20px;
    }
    
    .statIcon {
        width: 80px;
        flex-shrink: 0;
    }
    
    .connectBlock {
        height: 550px;
        max-width: 100%;
        width: 100%;
    }
}
@media (max-width: 950px) {
    .statBlock {
        width: calc(33% - 10px);
    }

    .statBlockTitle {
        font-size: 44px;
    }

    .statBlockText {
        font-size: 18px;
    }

    .statIcon {
        width: 50px;
        flex-shrink: 0;
    }
}

@media (max-width: 800px) {
    .statIcon {
        display: none;
    }
}

@media (max-width: 730px) {
    .noticesBlock {
        max-width: 220px;
    }

    .notice {
        height: 45px;
    }

    .notice span {
        font-size: 18px;
    }

    .blocks {
        margin: 20px 30px 30px 30px;
    }
    
    .connectBlock {
        padding: 40px 50px;
    }

    .content {
    }

    .title {
        font-size: 28px;
    }

    .logo {
        width: 50px;
        height: 52px;
    }

    .connectText {
        font-size: 12px;
        margin-top: 10px;
    }

    .input:first-child {
        margin-top: 20px;
    }

    .input {
        margin-top: 10px;
        font-size: 14px;
        height: 40px;
    }

    .inputErrorMessage {
        font-size: 11px;
    }

    .checkbox {
        font-size: 13px;
    }

    .checkbox input+label::before {
        width: 18px;
        height: 18px;
    }

    .subButtonBox {
        margin-top: 10px;
        justify-content: right;
    }

    .submitButton {
        font-size: 14px;
    }

    .connectBlock {
        height: 500px;
    }
}

@media (max-width: 600px) {
    .title {
        font-size: 24px;
    }
    
    .statBlockTitle {
        font-size: 36px;
    }

    .statBlockText {
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .contactUs {
        margin-bottom: 15px;
    }
    .blocks {
        margin: 15px;
    }
    
    .connectBlock {
        padding: 25px;
    }

    .content {
    }

    .title {
        font-size: 20px;
    }

    .logo {
        width: 40px;
        /*height: 40px;*/
    }

    .connectText {
        font-size: 12px;
        margin-top: 10px;
    }

    .input:first-child {
        margin-top: 20px;
    }

    .input {
        margin-top: 5px;
        font-size: 12px;
        border-radius: 2px;
        padding: 0 0 0 15px;
    }

    .inputErrorMessage {
        font-weight: 400;
        font-size: 10px;
    }

    .checkbox {
        font-size: 12px;
    }

    .checkbox input+label::before {
        width: 18px;
        height: 18px;
    }

    .subButtonBox {
        margin-top: 0;
        justify-content: right;
    }

    .submitButton {
        font-size: 13px;
    }

    .statBlockTitle {
        font-size: 32px;
    }

    .statBlockText {
        font-size: 12px;
    }
    .statBlock {
        width: calc(33% - 4px);
        border: none;
        padding: 15px 10px;
    }
    
    .connectBlock {
        height: 465px;
    }
}

@media (max-width: 400px) {
    .container {
        padding-top: 20px;
    }
    
    .statBlocks {
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
    }

    .statBlock {
        width: 100%;
        height: 100%;
        padding: 15px 20px;
    }

    .statBlock div {
        display: flex;
        width: 100%;
        justify-content: start;
        gap: 10px;
        align-items: center;
    }

    .statBlockText {
        font-size: 16px;
    }

    .connectBlock {
        height: 500px;
    }
}