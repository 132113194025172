

.filterButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 40px;
    background-color: var(--filter-button-bg);
    color: var(--filter-button-color);
    font-size: 16px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    transition: all ease-out 400ms;
    font-weight: 400;
}

.filterButton span {
    display: block;
    margin: 0 20px;
}

.filterButton:hover, .filterButtonHovered {
    background-color: #25427E;
    color: white;
}

@media (max-width: 750px) {

    .filterButton {
        font-size: 14px;
        height: 35px;
    }

    .filterButton span {
        margin: 0 15px;
    }
}