

.container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    /*background-color: #A7BCDB;*/
    font-family: "Nunito", sans-serif;;

}

.aboutUsBG {
    padding: 60px 0 80px 0;
    width: 100%;
    transition: all 500ms ease-out;
    background-color: var(--about-us-bg);
}

.aboutUs {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 800px;
    width: 100%;
}

.ourBlock {
    transition: 500ms all 500ms ease-out;
    opacity: 0;
}

.hidden {
    opacity: 1;
}

.aboutUsTitle {
    font-family: "Nunito", sans-serif;;
    height: 40px;
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    color: var(--about-us-text-color);
}

.presentation {
    position: absolute;
    top: 70px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}


.logo * {
    pointer-events: auto;
    cursor: pointer;
    animation: pulse 2s linear infinite;
}

.logo path {
    transition: all 500ms ease-in;
    fill: #eef5ff;
    stroke-width: 1.5px;
}

.logo path:nth-child(even) {
    stroke: #52B26E
}

.logo path:nth-child(odd) {
    stroke: #2358A4
}

.logoBlock {
    pointer-events: none;
    position: absolute;
    width: 55%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* закрашивание лого и скрытие рамки элементов */
.logoHovered path:nth-child(even) {
    fill: #52B26E;
    stroke: transparent;
}

.logoHovered path:nth-child(odd) {
    fill: #2358A4;
    stroke: transparent;
}

.logoHovered * {
    animation: none;
}

.line {
    stroke-dasharray: 470;
    stroke-dashoffset: 470;
    transition: all 1000ms 400ms ease-in, stroke 300ms ease-in-out;
}

/* свойства появляющихся линий */
.logoLines .hovered {
    stroke: var(--about-us-stroke-color);
    stroke-dashoffset: 0;
}

.backLineHovered {
    stroke: var(--about-us-back-line-stroke-color) !important;
    opacity: 1 !important;
    filter: blur(10px);
}

.backLine {
    stroke: #d0e8ff;
    opacity: 0.4;
    transition: 500ms all 500ms;
}



.secondBlock, .thirdBlock, .fourthBlock {
    /*display: flex;*/
    /*flex-direction: column;*/
    position: absolute;
    border-radius: 6px;
    padding: 0 20px;
    max-width: 390px;
    box-shadow: 0 0 14px 4px rgba(108, 153, 241, 0.1);
    display: table;  /* блок с абс позиционироваие имеет автоматическую высоту и длину */
    /*height: auto;*/
    background-color: white;
    transition: 800ms all 400ms ease-out;
}

.secondBlock .aboutRow:last-child,
.thirdBlock .aboutRow:last-child,
.fourthBlock .aboutRow:last-child {
    margin-bottom: 0;
    border-bottom: none;
}

/* при наведении на лого блоки сдвигаются */
.secondBlockHovered,
.thirdBlockHovered,
.fourthBlockHovered {
    /*background-color: #e1effd;*/
    background-color: white !important;
    box-shadow: 0 0 14px 4px rgba(108, 153, 241, 0.25) !important;
}

.fourthBlockHovered {
    left: 10px !important;
}

.secondBlockHovered,
.thirdBlockHovered {
    right: 10px !important;
}

.secondBlockHovered {
    top: 20px !important;
}

.thirdBlockHovered,
.fourthBlockHovered {
    bottom: 20px !important;
}

.secondBlock {
    margin: 0 0 auto auto;
    top: 40px;
    right: 40px;
}

.thirdBlock {
    margin: auto 0 0 auto;
    bottom: 40px;
    right: 40px;
}

.fourthBlock {
    margin: auto auto 0 0;
    bottom: 40px;
    left: 40px;
}

.starr_block {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
}

.starr {
    width: 100%;
    height: 100%;
}

.aboutRow {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 20px;
    border-bottom: rgba(35, 88, 163, 0.3) 1px solid;
}

.aboutRow .starr_block {
    margin-right: 10px;
}

.leftTextBlock,
.rightTextBlock {
    font-family: "Nunito", sans-serif;;
    overflow: hidden;
    border-right: 3px transparent solid;
    border-left: 3px transparent solid;
    position: absolute;
    top: 50%;
    font-size: 40px;
    font-weight: 200;
    color: var(--about-us-text-color);
    transform: translate(0, -50%);
    /*transition: border 400ms ease-in, max-width 800ms ease-in, padding 800ms ease-in;*/
    transition: border 400ms ease-in, max-width 1200ms linear, padding 1200ms linear;
    max-width: 0;
    padding: 20px 0;
}

.leftTextBlock {
    left: 0;

}

.rightTextBlock {
    right: 0;
    text-align: right;
}

.leftTextBlock span,
.rightTextBlock span {

    white-space: nowrap;
    transition: all 800ms ease-in;
    opacity: 0;
}

.rightTextBlockHovered,
.leftTextBlockHovered {
    opacity: 1;
    border-right: 3px var(--about-us-border-color) solid;
    border-left: 3px var(--about-us-border-color) solid;
    max-width: 500px;
    padding: 20px 40px;
}

.rightTextBlockHovered span,
.leftTextBlockHovered span {
    opacity: 1;
}


@keyframes pulse {
    0% {
        fill: #eef5ff;
    }
    50% {
        fill: #c8ddff;
        /*fill: #a7c9ff;*/

    }

    100% {
        fill: #eef5ff;

    }
}

@media (max-width: 1040px) {

    .presentation {
        top: 50px;
    }

    .aboutUs {
        height: 650px;
    }


    .aboutUsTitle {
        font-size: 34px;
    }

    .leftTextBlock, .rightTextBlock {
        font-size: 30px;
        padding: 15px 0;
    }

    .rightTextBlockHovered,
    .leftTextBlockHovered {
        border-right: 3px #c9e0ff solid;
        border-left: 3px #c9e0ff solid;
        max-width: 300px;
        padding: 15px 20px;
    }
}

@media (max-width: 900px) {

    .presentation {
        top: 50px;
    }

    .aboutUs {
        height: 550px;
    }

    .logoBlock,
    .leftTextBlock,
    .rightTextBlock {
        display: none;
    }


}


@media (max-width: 820px) {
    .aboutUsBG {
        padding: 20px 0 50px 0;
        height: 1100px;
        overflow: hidden !important;
    }

    .aboutUsTitle {
        font-size: 30px;
    }

    .presentation {
        display: flex;
        position: static;
        flex-direction: column;
    }
    
    .presentation .ourBlock:nth-child(even) {
        margin-left: auto;
    }
}


@media (max-width: 630px) {

    .aboutUsBG {
        padding: 20px 0;
        height: 1120px;
    }

    .aboutUsTitle {
        font-size: 24px;
    }

    .container {
        padding: 0 8px;
    }

    .presentation {
align-items: center;
    }
    .presentation .ourBlock:nth-child(even) {
        margin: auto;
    }
}

@media (max-width: 400px) {
    .aboutUsBG {
        padding: 20px 0;
        height: 1170px;
    }
}
