.fullContainer {
    background-color: var(--how-we-work-bg);
    transition: all 500ms ease-out;
    padding-bottom: 20px;
    cursor: default;
}

.fullContainer * {
    /*transition: all 500ms ease-out;*/
}

.container {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 10px;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    transition: all 500ms ease-out;
    background-color:  var(--how-we-work-bg);
}

.contactBlock {
    flex-shrink: 0;
    padding-top: 40px;
    width: 400px;
    height: 600px;
}

.title {
    transition: all 500ms ease-out;
    display: block;
    font-size: 44px;
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 50px;
}

.contact {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.contact span {
    transition: all 500ms ease-out;
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    color: var(--text-color);
}

.contact a {
    text-decoration: underline;
    color: var(--navbar-navbar-buttons-active-color);
    transition: all 300ms ease-in-out;
    max-width: 140px;
}

.contact a:hover {
    opacity: 0.8;
}

.iconStroke {
    width: 24px;
    height: 24px;
}

.iconFill {
    width: 24px;
    height: 24px;
}

.iconStroke * {
    transition: all 500ms ease-out;
    stroke: var(--contact-icon-color);
}

.iconFill * {
    transition: all 500ms ease-out;
    fill: var(--contact-icon-color);
}

.mapBlock {
    margin-top: 40px;
    height: 600px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    transition: all 500ms ease-out;
}

.map {
    width: 100%;
    height: 600px;
}

a {
    text-decoration: none;
}


@media (max-width: 850px) {
    .title {
        font-size: 32px;
        margin-bottom: 30px;
    }
    .container {
        flex-direction: column;
    }
    
    .mapBlock {
        border-radius: 4px !important;
        overflow: hidden !important;
        margin-top: 30px;
        height: 400px;
    }
    
    .contact:last-child {
        margin-bottom: 0;
    }
    
    .contact {
        margin-bottom: 30px;
    }
    
    .contactBlock {
        padding-top: 20px;
        height: auto;
        width: auto;
    }
}

@media (max-width: 430px) {
    .title {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .mapBlock {
        margin-top: 20px;
        height: 300px;
    }

    .contact span {
        font-size: 14px;
    }

    .contact {
        margin-bottom: 20px;
    }
}
