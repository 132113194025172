.fullContainer {
    background-color: var(--how-we-work-bg);
    transition: all 500ms ease-out;
    padding-bottom: 20px;
    cursor: default;
}

.fullContainer * {
    transition: all 500ms ease-out;
}

.container {
    position: relative;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    transition: all 500ms ease-out;
    background-color:  var(--how-we-work-bg);
}

.mainTitle {
    font-size: 32px;
    font-weight: 500;
    color: var(--text-color);
}

.infoContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

@media (max-width: 630px) {
    .infoContainer {
        flex-direction: column;
    }

}

.navigationBlock {
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 200px;
    /*background-color: rgba(35, 88, 164, 0.24);*/
    flex-shrink: 0;
}

.navigationTitle {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
}

.navigationContentTitle {
    display: block;
    font-size: 12px;
    opacity: 0.8;
    font-weight: 400;
    color: var(--text-color);
    margin-left: 20px;
     text-decoration: none;
    cursor: pointer;
    transition: all 200ms ease-out;
}

.navigationContentTitle:hover {
    opacity: 1;
}

.infoBlock {
    margin-bottom: 40px;
}

.infoBlock:last-child {
    margin-bottom: 0;
}

.info {
    position: relative;
    width: 100%;
    
    padding: 20px;
    background-color: var(--about-company-bg-color);
    border-radius: 10px;
    transition: all 500ms ease-out;
}

.info:hover {
    background-color: var(--about-company-bg-color-hover);
}

.infoMargin {
    padding-top: 85px;
    margin-top: -65px;
    -webkit-background-clip: content-box;
    background-clip: content-box;
    height: 20px;
}


.contentTitle {
    display: block;
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 10px;
    color: var(--text-color);
    cursor: default;
}

.contentText {
    display: inline-block;
    font-size: 16px;
    color: var(--text-color);
    font-weight: 300;
    cursor: default;
    margin-bottom: 5px;
}

.contentText:last-of-type {
    margin-bottom: 0;
}

.navigation {
    margin: 10px 0;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 6px;
    padding: 8px 0;
    color: var(--project-text-color);
    transition: all 500ms ease-in-out;
}


.navigation span {
    display: block;
    cursor: default;
}

.navigation a {
    text-decoration: none;
    color: var(--project-text-color);
    white-space: nowrap;
    transition: all 500ms ease-in-out;
}

.navigation svg * {
    stroke: var(--project-text-color);
    transition: all 500ms ease-in-out;
}


.navigation a:hover {
    color: #2358A4;
}

@media (max-width: 1040px) {
    .navigation {
        margin-top: 5px;
    }
}

@media (max-width: 730px) {
    .navigation {
        font-size: 14px;
    }
    
    .mainTitle {
        font-size: 26px;
    }
    
    .contentTitle {
        font-size: 22px;
    }
    
    .contentText {
        font-size: 14px;
    }
    
}

@media (max-width: 670px) {

}

