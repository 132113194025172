.checkbox {
    /*width: 130px;*/
    font-size: 17px;
    font-family: "Noah Head", sans-serif;
    font-weight: 400;
    margin-right: 100px;
    margin-bottom: 10px;
}

/* скрыли стандартный чекбокс */
.checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin-right: 15px;
}

/* выровняли */
.checkbox input+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

/* отрисовываем новую иконку */
.checkbox input+label::before {
    display: inline-block;
    cursor: pointer;
    content: '';
    width: 23px;
    height: 23px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #b3d7ff;
    box-shadow: inset 0 0 5px 2px rgba(35, 88, 164, 0.2);
    -moz-box-shadow: inset 0 0 5px 2px rgba(35, 88, 164, 0.2);
    border-radius: 4px;
    margin-right: 15px;
}

/* если нажали на чекбокс */
.checkbox input:checked+label::before {
    /* Подключение SVG */
    background: url("../../../image/check.svg") no-repeat;
}

/* стили при наведении курсора на checkbox */
/*.type input:not(:disabled):not(:checked)+label:hover::before {*/
.checkbox input:not(:disabled)+label:hover::before {
    background-color: #edf5ff;
}

/* стили для активного состояния чекбокса (при нажатии на него) */
.checkbox input:not(:disabled):active+label::before {
    background-color: #fff;
}


.checkbox input:disabled+label::before {
    background-color: #e9ecef;
}

/*.checkbox label:last-child {*/
/*    width: 190px;*/
/*}*/

@media (max-width: 1040px) {
    .checkbox {
        font-size: 16px;
    }
}

@media (max-width: 730px) {
    .checkbox {
        margin-right: 0;
    }
}

@media (max-width: 630px) {
    .checkbox {
        font-size: 14px;
    }
    .checkbox input+label::before {
        width: 22px;
        height: 22px;
    }
}

