.sliderBlock {
    width: 100%;
    margin-top: 10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 10;
    /*background-color: #c8ddff;*/
}
.currentPhotoBlock {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    z-index: 10;
    width: 100%;
    /*height: 710px;*/
    padding-bottom: 50%;
    box-shadow: inset 0 0 15px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 300ms ease-in-out, height 0;
}

.loadBG {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    animation: logoBlink 2s infinite ease-in-out;
}

.currentPhotoBlockOpened {
    position: fixed;
    z-index: 20;
    padding-bottom: 0;
    top: 0;
    left: 0;
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    height: 100vh;
    width: 100%;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.21);
    backdrop-filter: blur(5px);
}

.currentPhoto {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 5px !important;
    width: 100%;
    max-height: 100vh;
    height: 100%;
    object-fit: cover;
    transition: all 300ms ease-in-out;
    animation: appear 1s ease-in-out;
    -webkit-animation-fill-mode: forwards !important; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards !important;    /* FF 5+ */
    -o-animation-fill-mode: forwards !important;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards !important;     /* IE 10+ */
    animation-fill-mode: forwards !important;         /* When the spec is finished */
}

.insetShadow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    border-radius: 5px;
    box-shadow: inset 0 0 15px 10px rgba(0, 0, 0, 0.25);
}

.currentPhotoOpened {
    object-fit: contain;
    height: auto !important;
}

.shadow {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.51);
}

.closeButton {
    display: block !important;
    position: fixed;
    cursor: pointer;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 21;
}

.imageLogo {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 70px;
    height: 70px;
    z-index: -9;
}

.arrowLeftBlock {
    position: absolute;
    width: 12%;
    height: 100%;
    z-index: 50;
    left: 0;
    top: 0;
    opacity: 0;
    background: linear-gradient(90deg, #000000 -78.15%, rgba(0, 0, 0, 0) 100%);
    transition: all 200ms ease-in;
    cursor: pointer;
}

.arrowLeft {
    position: absolute;
    width: 8%;
    height: 100%;
    z-index: 10;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.arrowLeft *, .arrowRight * {
    stroke: var(--text-color);
}

.arrowRightBlock {
    position: absolute;
    width: 8%;
    height: 100%;
    z-index: 50;
    right: 0;
    top: 0;
    opacity: 0;
    background: linear-gradient(270deg, #000000 -78.15%, rgba(0, 0, 0, 0) 100%);
    transition: all 200ms ease-in;
    cursor: pointer;
}

.arrowRight {
    position: absolute;
    width: 8%;
    height: 100%;
    z-index: 5;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
}

.arrowLeftBlock:hover, .arrowRightBlock:hover {
    opacity: 1;
}

.sliderBottomBlock {
    position: relative;
    width: 100%;
    height: 90px;
    margin-top: 15px;
    overflow: hidden;
    -ms-overflow-style: none;
}

.sliderBottom {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    transition: all 250ms ease-in;
}

.miniPhotoBlock {
    /*transform:rotate(90deg);*/
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    width: 130px;
    margin-right: 20px;
    z-index: 3;
    height: 90%;
    box-shadow: inset 0 0 15px 10px rgba(0, 0, 0, 0.25);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 200ms ease-in;
}

.miniPhotoBlock:hover {
    height: 100%;
}

.miniPhotoBlock:hover .miniPhoto {
    filter: brightness(1);
}

.miniPhotoBlockActive {
    /*width: 140px;*/
    height: 100%;
    object-fit: cover
}

.miniPhotoBlockActive .miniPhoto {
    filter: brightness(1);

}

.miniPhotoBlock:last-child {
    margin-right: 0;
}

.miniPhoto {
    position: absolute;
    filter: brightness(0.6);
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 5px !important;
    object-fit: cover;
    transition: all 200ms ease-in;
    animation: appear 1s ease-in-out;
    -webkit-animation-fill-mode: forwards !important; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards !important;    /* FF 5+ */
    -o-animation-fill-mode: forwards !important;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards !important;     /* IE 10+ */
    animation-fill-mode: forwards !important;         /* When the spec is finished */
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@media (max-width: 1040px) {
    .arrowLeftBlock, .arrowRightBlock {
        width: 15%;
    }
    .imageLogo {
        width: 60px;
        height: 60px;
    }
    .sliderBottomBlock {
        height: 80px;
        margin-top: 12px;
    }

    .miniPhotoBlock {
        width: 115px;
        margin-right: 18px;
    }
}
@media (max-width: 730px) {

    .arrowLeftBlock, .arrowRightBlock {
        width: 20%;
    }
    .imageLogo {
        width: 50px;
        height: 50px;
    }
    .currentPhotoBlock {
        padding-bottom: 55%;
    }
    .sliderBottomBlock {
        height: 60px;
        margin-top: 10px;
    }

    .miniPhotoBlock {
        width: 95px;
        margin-right: 15px;
    }

}

@media (max-width: 500px) {
    .imageLogo {
        width: 40px;
        height: 40px;
    }
    .currentPhotoBlock {
        padding-bottom: 60%;
    }
    .sliderBottomBlock {
        height: 50px;
        margin-top: 8px;
    }

    .miniPhotoBlock {
        width: 80px;
        margin-right: 12px;
    }
}

@keyframes logoBlink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}