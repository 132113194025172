.block {
    border-top: var(--our-block-border-color);
    border-left: var(--our-block-border-color);
    display: block;
    position: absolute;
    border-radius: 22px !important;
    height: 260px;
    width: 400px;
    transition: 800ms all 400ms ease-out, width 600ms ease-in-out, height 600ms ease-in-out;
    background: transparent;

}

.blockBg {
    border-radius: 22px;
    backdrop-filter: blur(12px);
    height: 100%;
    width: 100%;
}

.firstBlock .block {
    top: 40px;
    left: 40px;
}

.secondBlock .block {
    top: 40px;
    right: 40px;
}

.thirdBlock .block {
    right: 40px;
    bottom: 40px;
}

.forthBlock .block {
    bottom: 40px;
    left: 40px;
}

/* при наведении на лого блоки сдвигаются */
.blockHovered {
    box-shadow: var(--our-block-box-shadow);
}

.firstBlock .blockHovered {
    left: 10px;
    top: 20px;
}

.firstBlock .blockBg, .thirdBlock .blockBg {
    background: var(--our-block-first-third-background)
}

.secondBlock .blockBg, .forthBlock .blockBg {
    background: var(--our-block-second-forth-background)
}

.secondBlock .blockHovered {
    right: 10px;
    top: 20px;
}

.thirdBlock .blockHovered {
    right: 10px;
    bottom: 20px;
}

.forthBlock .blockHovered {
    left: 10px;
    bottom: 20px;
}

.title {
    padding: 20px 30px;
    width: 100%;
    height: auto;
    text-align: center;
}

.title span {
    display: inline-block;
    color: white;
    font-size: 21px;
    margin-bottom: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
    font-family: "Nunito Sans", sans-serif;
}

.benefits {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
}

.benefit {
    cursor: default;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

.benefit span {
    color: white;
    font-size: 17px;
    font-family: "Nunito Sans", sans-serif;
}

.icon {
    flex: 0 0 auto;
    width: 25px;
    height: 25px;
    margin-right: 8px;
}

@media (max-width: 1040px) {
    .block {
        width: 360px;
        height: 220px;
    }

    .benefit {
        margin-bottom: 10px;
    }

    .benefit span {
        font-size: 16px;
    }

    .title {
        padding: 15px 15px 10px 15px;
    }
}

@media (max-width: 900px) {
    .block {
        margin-top: 25px;
    }

    .firstBlock .block {
        left: 10px;
        top: 20px;
    }

    .secondBlock .block {
        right: 10px;
        top: 20px;
    }

    .thirdBlock .block {
        right: 10px;
        bottom: 20px;
    }

    .forthBlock .block {
        left: 10px;
        bottom: 20px;
    }
}

@media (max-width: 820px) {
    .block {
        position: relative;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        width: 420px;
        height: 230px;
    }
    
    
}

@media (max-width: 430px) {
    .title span {
        font-size: 18px;
    }
    
    .block {
        width: 300px;
        height: 240px;
    }

    .benefit span {
        font-size: 14px;
    }
}


