.fullContainer {
    background-color: var(--project-bg);
    transition: all 500ms ease-out;
}

.container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    background-color: var(--project-bg);
    font-family: "Noah Text", sans-serif;
    transition: all 500ms ease-out;
}

.navigation {
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 6px;
    padding: 8px 0;
    color: var(--project-text-color);
    transition: all 500ms ease-in-out;
}

.navigation span {
    display: block;
    cursor: default;
}

.navigation a {
    text-decoration: none;
    color: var(--project-text-color);
    white-space: nowrap;
    transition: all 500ms ease-in-out;
}

.navigation svg * {
    stroke: var(--project-text-color);
    transition: all 500ms ease-in-out;
}


.navigation a:hover {
    color: #2358A4;
}

.projectName {
    display: block;
    font-family: "Noah Head", sans-serif;
    /*margin-top: 10px;*/
    color: var(--project-text-color);
    transition: all 500ms ease-in-out;
    font-size: 48px;
    font-weight: 400;
    text-align: center;
}

.projectInfoTags {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tag {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;
    color: var(--project-text-color);
    transition: all 500ms ease-in-out;
}

.tag:last-child {
    margin-right: 0;
}
.tag span {
    font-size: 18px;
    margin-left: 8px;
    white-space: nowrap;
}

.icon {
    width: 24px;
    height: 24px;
}


.descriptionBlock {
    position: relative;
    margin-top: 50px;
    width: 100%;
    color: var(--project-text-color);
    transition: all 500ms ease-in-out;
}

.logoLetter {
    position: absolute;
    right: 0;
    top: calc(40px);
    /*transform: translate(0, -50%);*/
    max-width: 300px;
    height: auto;
    width: 80%;
}

.descTitle {
    display: block;
    font-family: "Noah Head", sans-serif;
    font-size: 34px;
    font-weight: 400;
    text-align: center;
}

.descText {
    display: block;
    margin-top: 20px;
    font-size: 18px;
}

.descText p {
    display: block;
    margin-bottom: 30px;
}

.otherProjectsBlock {
    width: 100%;
    margin-top: 50px;
}

.otherProjectsTitle {
    font-size: 34px;
    font-weight: 400;
}

.otherProjects {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1040px) {
    .navigation {
        margin-top: 5px;
    }

    .projectName {
        font-size: 32px;
    }

    .tag span {
        font-size: 16px;
    }

    .icon {
        width: 22px;
        height: 22px;
    }

    .descTitle {
        font-size: 30px;
    }

    .descText {
        font-size: 18px;
    }

    .otherProjectsTitle {
        font-size: 32px;
    }
}
@media (max-width: 730px) {
    .navigation {
        font-size: 14px;
    }

    .projectInfoTags {
        flex-wrap: wrap;
    }
    .projectName {
        font-size: 28px;
    }

    .tag span {
        font-size: 14px;
    }

    .icon {
        width: 22px;
        height: 21px;
    }

    .descTitle {
        font-size: 26px;
    }

    .descText {
        font-size: 16px;
    }

    .otherProjectsTitle {
        font-size: 28px;
    }
}

@media (max-width: 500px) {
    .projectInfoTags {
        justify-content: left;
    }


    .projectName {
        font-size: 24px;
    }

    .tag span {
        font-size: 12px;
    }


    .descTitle {
        font-size: 22px;
    }

    .descText {
        font-size: 14px;
    }

    .otherProjectsTitle {
        font-size: 24px;
    }
}