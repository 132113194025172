.fullContainer {
    padding-top: 80px;
    background-color: var(--how-we-work-bg);
    transition: all 500ms ease-out;
    padding-bottom: 20px;
}

.container {
    position: relative;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    font-family: "Nunito", sans-serif;
    /*display: var(--how-we-work-light-visible);*/
    font-size: 16px;
    transition: all 500ms ease-out;
    background-color:  var(--how-we-work-bg);
}
/* WHITE SCHEME */

.u, .star, .el3, .k {
    opacity: 0.8;
}
.u {
    display: var(--how-we-work-light-display);
    position: absolute;
    width: 500px;
    height: 500px;
    top: -120px;
    left: -90px;
}

.star {
    display: var(--how-we-work-light-display);
    position: absolute;
    width: 500px;
    height: 500px;
    top: 450px;
    right: -30px;
}

.el3 {
    display: var(--how-we-work-light-display);
    position: absolute;
    width: 400px;
    height: 400px;
    top: 900px;
    left: -80px;
}

.k {
    display: var(--how-we-work-light-display);
    position: absolute;
    width: 400px;
    height: 400px;
    bottom: -20px;
    right: 20px;
}

.title {
    display: block;
    text-align: center;
    opacity: 0;
    font-size: 36px;
    font-weight: 400;
    color: var(--how-we-work-title);
    font-family: "Nunito", sans-serif;;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: translateY(30px);
    transition: 1000ms all 100ms ease-out;
}

.titleHovered {
    opacity: 1;
    transform: translateY(0);
}

.glassBlock {
    position: relative;
    margin: 25px auto 0 auto;
    width: calc(100% - 20px);
    padding: 20px 0;
    border-radius: 20px;
    border-top: var(--how-we-work-glass-block-border);
    border-left: var(--how-we-work-glass-block-border);
    background: var(--how-we-work-glass-block-bg);
    box-shadow: var(--how-we-work-glass-block-shadow);
    backdrop-filter: var(--how-we-work-glass-block-filter);
    z-index: 5;
}

.pila {
    position: absolute;
    width: 270px;
    height: 270px;
    top: 5%;
    left: 20%;
    display: var(--how-we-work-light-display);
}

.pila, .datchic, .lazer {
    width: 20%;
}

.datchic {
    position: absolute;
    top: 30%;
    right: 20%;
    display: var(--how-we-work-light-display);
}

.lazer {
    position: absolute;
    top: 55%;
    left: 25%;
    display: var(--how-we-work-light-display);
}


.drel {
    position: absolute;
    width: 25%;
    bottom: 5%;
    right: 20%;
    display: var(--how-we-work-light-display);
}

.contentRows {
    width: 100%;
} 

.contentRow {
    display: flex;
    padding: var(--how-we-work-content-row-padding);
    width: 100%;
    height: 420px;
}

.contentBlock {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    /*background-color: #ffe0e0;*/
}
.contentRow:nth-child(odd) .contentBlock {
    margin-left: auto;
}

.contentRow:nth-child(even) .contentBlock {
    align-items: end;
}

.contentRow:nth-child(odd) .timeLineBlock {
    display: var(--how-we-work-light-display-flex);
    justify-content: center;
    align-items: center;
    height: 70px;
    /*background-color: rgb(247, 250, 255);*/
    width: 80%;
}

.contentRow:nth-child(even) .timeLineBlock {
    display: var(--how-we-work-light-display-flex);
    justify-content: center;
    align-items: center;
    height: 70px;
    /*background-color: rgb(247, 250, 255);*/
    width: 80%;
}

.contentRow:nth-child(odd) .el1 {
    /*flex-shrink: 0;*/
    /*display: block;*/
    /*width: 25px !important;*/
    /*height: 25px !important;*/
    transform: translateX(-50%);
}

.contentRow:nth-child(even) .el1 {
    transform: translateX(50%);
}

.el2Block {
    position: relative;
    height: 90px;
    width: 90px;
}

.contentRow:nth-child(odd) .el2 {
    transform: translateX(-20px);
    height: 100%;
    width: 100%;
}

.contentRow:nth-child(even) .el2 {
    transform: translateX(20px);
    height: 100%;
    width: 100%;
}

.contentRow:nth-child(odd) .iconInElep {
    position: absolute;
    height: 55%;
    width: 55%;
    top: 50%;
    left: calc(50% - 20px);
    transform: translate(-50%, -50%);
}

.contentRow:nth-child(even) .iconInElep {
    position: absolute;
    height: 55%;
    width: 55%;
    top: 50%;
    left: calc(50% + 20px);
    transform: translate(-50%, -50%);
}

.contentRow:nth-child(odd) .union {
    transform: translateX(-13px);
}

.contentRow:nth-child(even) .union {
    transform: translateX(13px);
}

.contentRow:nth-child(odd) .content {
    display: flex;
    justify-content: start;
    gap: 30px;
    height: 350px;
}

.contentRow:nth-child(even) .content {
    display: flex;
    justify-content: end;
    gap: 30px;
    height: 350px;
}

.contentRow:nth-child(odd) .arrowDown {
    display: var(--how-we-work-light-display);
    height: calc(100% + 37px);
    transform: translate(-50%, -20px);
}

.contentRow:nth-child(even) .arrowDown {
    display: var(--how-we-work-light-display);
    height: calc(100% + 37px);
    transform: translate(50%, -20px);
}

.contentRow:nth-child(odd) .textContent {
    height: 100%;
    width:  calc(100% - 100px);
    /*background-color: #e5ffeb;*/
}

.contentRow:nth-child(even) .textContent {
    text-align: end;
    height: 100%;
    width:  calc(100% - 100px);
    /*background-color: #e5ffeb;*/
}

.stepTitleRight {
    opacity: 0;
    display: block;
    color: var(--how-we-work-step-title-color-right);
    font-size: 28px;
    transform: translate(30px, var(--how-we-work-step-title-translateY));
    transition: 1000ms all ease-out;
    margin-bottom: var(--how-we-work-step-title-margin-bottom);
}

.stepTitleLeft {
    float: right;
    opacity: 0;
    display: block;
    color: var(--how-we-work-step-title-color-left);
    font-size: 28px;
    transform: translate(-30px, var(--how-we-work-step-title-translateY));
    transition: 1000ms all ease-out;
    margin-bottom: var(--how-we-work-step-title-margin-bottom);
}

.stepTitleHovered {
    opacity: 1;
    transform: translate(0, var(--how-we-work-step-title-translateY));
}

.stepText {
    display: inline-block;
    color: var(--how-we-work-glass-block-text-color);
    font-size: 18px;
    font-weight: 300;
    text-align: justify;
    opacity: 0;
    transform: translateY(40px);
    transition: 900ms all 300ms ease-out;
    margin-bottom: 20px;
}

.stepText:last-of-type {
    margin-bottom: 0;
}


.stepTextLink {
    float: right;
    cursor: pointer;
    display: inline-block;
    padding: 7px 10px;
    margin-top: 10px;
    border-radius: 6px;
    background-color: var(--how-we-work-glass-block-text-link-bg);
    color: var(--how-we-work-glass-block-text-color);
    font-size: 16px;
    font-weight: 300;
    opacity: 0;
    transform: translateY(40px);
    transition: 900ms all 300ms ease-out, color 500ms ease-in-out, background-color 500ms ease-in-out;
}

.stepTextLink:hover {
    color: var(--how-we-work-glass-block-text-link-color-hover);
    background-color: var(--how-we-work-glass-block-text-link-bg-hover);
}

.stepTextHovered {
    opacity: 1;
    transform: translateY(0);
}
/* BLACK SCHEME */
.uDark {
    display: var(--how-we-work-dark-display);
    position: absolute;
    width: 500px;
    height: 500px;
    top: -120px;
    left: -90px;
}

.greenLines {
    display: var(--how-we-work-dark-display);
    position: absolute;
    width: 350px;
    height: 350px;
    top: 450px;
    right: -30px;
    z-index: 1200;
}

.darkGreenLinesBlurredCircle {
    display: var(--how-we-work-dark-display);
    position: absolute;
    width: 230px;
    height: 290px;
    top: 420px;
    right: 30px;
    background-color: rgba(4, 155, 97, 0.26);
    border-radius: 100px;
    filter: blur(65px);
}

.blueLines {
    display: var(--how-we-work-dark-display);
    position: absolute;
    width: 300px;
    height: 300px;
    top: 800px;
    left: -80px;
}

.starDark {
    display: var(--how-we-work-dark-display);
    position: absolute;
    width: 400px;
    height: 400px;
    bottom: -90px;
    right: 20px;
}


.timeLineDarkBlock {
    display: var(--how-we-work-dark-display-flex);
    justify-content: start;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 20px;
}

.timeLineDarkBlock:nth-child(odd) {
    transform: translate(50%, 0);
}

.timeLineDarkBlock:nth-child(even) {
    transform: translate(-50%, 0);
}

.lineDown {
    margin-top: 20px;
    height: 80%;
}

.darkCircleBlurredBlock {
    position: relative;
    width: 60px;
    height: 60px;
    opacity: 0;
    transition: 1000ms all 300ms ease-out;
}

.darkCircleBlurredBlockHovered {
    opacity: 1;
}

.darkCircleBlurredBlue {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 41, 255, 0.82);
    border-radius: 50px;
    filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    z-index: 1000;
}

.darkCircleBlurredGreen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 171, 63, 0.73);
    border-radius: 50px;
    filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    z-index: 1000;

}

.iconInBlu1 {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 50%;
    left: 50%;
    z-index: 1200;
    transform: translate(-50%, -50%);
}



.iconInBlu1 * {
    fill: white;
}

.iconInBlu2 {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 50%;
    left: 50%;
    z-index: 1200;
    transform: translate(-50%, -50%);
}

.iconInBlu2 * {
    stroke: white;
}

@media (max-width: 1250px) {
    .title {
        font-size: 32px;
    }
    
    .stepTitleRight, .stepTitleLeft {
        font-size: 24px;
        max-width: 240px;
    }

    .stepText {
        font-size: 18px;
    }

    .contentRow:nth-child(odd) .union {
        transform: translateX(-11px);
    }

    .contentRow:nth-child(even) .union {
        transform: translateX(11px);
    }


    .contentRow:nth-child(odd) .el2 {
        transform: translateX(-17px);
    }

    .contentRow:nth-child(even) .el2 {
        transform: translateX(17px);
    }

    .contentRow:nth-child(odd) .iconInElep {
        left: calc(50% - 17px);
    }

    .contentRow:nth-child(even) .iconInElep {
        left: calc(50% + 17px);
    }
}


@media (max-width: 950px) {
    .stepTitleRight, .stepTitleLeft {
        font-size: 22px;
    }

    .stepText {
        font-size: 16px;
    }
    
    .stepTextLink {
        font-size: 14px;
    }
    .contentRow {
        height: 365px;
    }

    .contentRow:nth-child(odd) .arrowDown {
        height: calc(95%);
        transform: translate(-50%, -22px);
    }

    .contentRow:nth-child(even) .arrowDown {
        display: var(--how-we-work-light-display);
        height: calc(95%);
        transform: translate(50%, -22px);
    }

    .drel {
        position: absolute;
        width: 25%;
        bottom: -2%;
        right: 15%;
        display: var(--how-we-work-light-display);
    }
    
    .contentRow .textContent {
        width:  calc(100% - 50px) !important;
    }

    .contentRow:nth-child(odd) .content {
        gap: 10px;
    }

    .contentRow:nth-child(even) .content {
        gap: 10px;
    }

    .contentRow:nth-child(odd) .union {
        transform: translateX(-9px);
    }

    .contentRow:nth-child(even) .union {
        transform: translateX(9px);
    }

    .contentRow:nth-child(odd) .el2 {
        transform: translateX(-15px);
    }

    .contentRow:nth-child(even) .el2 {
        transform: translateX(15px);
    }

    .contentRow:nth-child(odd) .iconInElep {
        left: calc(50% - 15px) !important;
    }

    .contentRow:nth-child(even) .iconInElep {
        left: calc(50% + 15px) !important;
    }
}

@media (max-width: 750px) {

    .title {
        font-size: 30px;
    }
    
    .stepTextHovered {
        transform: translateY(-10px);
    }
    .contentRow .contentBlock {
        align-items: start !important;
        margin-left: var(--time-line-margin-left) !important;
        width: 100%;
    }

    .timeLineDarkBlock {
        transform: none !important;
    }

    .pila, .datchic, .lazer, .drel {
        display: none;
    }

    .contentRow:nth-child(even) .timeLineBlock {
        flex-direction: row-reverse;
    }

    .contentRow:nth-child(even) .timeLineBlock .union {
        transform: rotate(180deg);
    }

    .contentRow:nth-child(even) .el2 {
        transform: translateX(-15px);
    }

    .contentRow:nth-child(odd) .iconInElep {
        left: calc(50% - 8px) !important;
    }

    .contentRow:nth-child(even) .iconInElep {
        left: calc(50% - 6px) !important;
    }

    .contentRow .el1 {
        transform: none !important;
    }

    .contentRow .arrowDown {
        transform: translateY(-15px) !important;
    }

    .contentRow .textContent {
        width:  100% !important;
        padding-right: 15px;
        /*background-color: #e5ffeb;*/
    }

    .contentRow:nth-child(even) .content {
        flex-direction: row-reverse;
    }

    .contentRow:nth-child(even) .content {
        justify-content: start;
    }

    .stepTitleLeft {
        text-align: left;
        float: left;
    }
    
    .el1 {
        width: 20px !important;
        flex-shrink: 0;
    }

    .contentRow:nth-child(even) .union {
        transform: rotate(180deg) translateX(2px) !important;
        width: 70% !important;
        flex-shrink: 0;
    }

    .contentRow:nth-child(odd) .union {
        transform: translateX(0) !important;
        width: 70% !important;
        flex-shrink: 0;
    }

    .contentRow .timeLineBlock {
        justify-content: start !important;
        width: 100% !important;
    }

    .el2Block {
        position: relative;
        height: 60px;
        width: 60px;
    }

    .contentRow .el2 {
        transform: translateX(-8px) !important;
    }

    .u {
        opacity: 0.75;
        width: 250px;
        height: 250px;
        top: 30px;
        left: -40px;
    }

    .star {
        opacity: 0.75;
        width: 250px;
        height: 250px;
        top: 500px;
        right: -30px;
    }

    .el3 {
        opacity: 0.75;
        width: 250px;
        height: 250px;
        top: 900px;
        left: -80px;
    }
    .k {
        opacity: 0.75;
        width: 250px;
        height: 250px;
        bottom: -10px !important;
        right: -30px !important;
    }

    .uDark {
        width: 250px;
        height: 250px;
        top: 60px;
        right: 40px;
        left: auto;
    }
    
    .blueLines {
        width: 220px;
        height: 220px;
        top: 950px;
        left: 30px;
    }
    
    .greenLines {
        width: 200px;
        height: 200px;
        top: 650px;
        right: 20px;
    }
    
    .darkGreenLinesBlurredCircle {
        width: 230px;
        height: 290px;
        top: 560px;
        right: 10px;
    }
    
    .starDark {
        width: 250px;
        height: 250px;
        bottom: -10px;
        right: 20px;
    }
    
}


@media (max-width: 550px) {

    .title {
        font-size: 24px;
    }
    
    .glassBlock {
        margin: 0;
        width: 100%;
    }

    .stepTitleRight, .stepTitleLeft {
        font-size: 20px;
        max-width: 180px;
    }

    .stepText {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .contentRow {
        height: 500px;
    }
    
    .lineDown {
        transform: translateY(75px) scaleY(170%) !important;
    }

    .contentRow .arrowDown {
        /*flex-shrink: 0 !important;*/
        /*height: calc(100% + 100px) !important;*/
        /*width: 40px;*/
        transform: translateY(45px) scaleY(138%) !important;
    }

    .star {
        top: 650px;
    }

    .el3 {
        top: 1200px;
    }
    .k {
        opacity: 0.75;
        width: 250px;
        height: 250px;
        bottom: -90px;
        right: 0;
    }
    .uDark {
        width: 250px;
        height: 250px;
        top: 140px;
        right: 40px;
        left: auto;
    }

    .blueLines {
        width: 220px;
        height: 220px;
        top: 1300px;
        left: 30px;
    }

    .greenLines {
        width: 200px;
        height: 200px;
        top: 800px;
        right: 20px;
    }

    .darkGreenLinesBlurredCircle {
        width: 230px;
        height: 290px;
        top: 710px;
        right: 10px;
    }
    
}
