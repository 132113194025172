/* подложка за хедером */
.shadow_nav {
    width: 100%;
    background-color: var(--navbar-background-color);
    height: 80px;
}

.navbar {
    position: fixed;
    width: 100%;
    height: 80px;
    top: -90px;
    font-family: "Noah Head", sans-serif;
    font-weight: 400;
    background-color: var(--navbar-background-color);
    z-index: 20;
    transition: all 300ms ease-in-out, height 0ms;
}

.navbar_opened {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--navbar-background-color);
    z-index: 20;
}

.navbar .container {
    display: flex;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    height: 100%;
}

.navbar hr {
    color: #2358A4; /* Цвет линии для остальных браузеров */
    background-color: #2358A4; /* Цвет линии для браузера Firefox и Opera */
    height: 1px; /* Толщина линии */
    opacity: 10%;
    border: none;
}

.navbar .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.navbar .logoBlock {
    display: flex;
    align-items: center;
}

.logo {
    width: 55px;
    height: 55px;
}

.logoBlock a {
    display: flex;
}

.logo_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    font-family: "Noah Text", sans-serif;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.logo_text span:nth-child(odd) {
    color: #2358A4;
}

.logo_text span:nth-child(even) {
    color: #52B26E;
}

.shadow {
    position: fixed;
    background-color: transparent;
    transition: all 900ms ease-in-out;
}

.changeThemeToggle {
    position: relative;
    padding: 2px;
    display: flex;
    align-items: center;
    height: 28px;
    width: 54px;
    background-color: #7C7B7A;
    border: #7C7B7A;
    border-radius: 20px;
    margin-right: 20px;
    cursor: pointer;
    transition: all 500ms ease-out;

}

.toggle {
    position: relative;
    transition: all 500ms ease-out;
    height: 22px;
    width: 22px;
    transform: var(--toggle-transform);
    border-radius: 20px;
    background-color: var(--toggle-color);
}

.iconSunTheme {
    display: var(--toggle-sun-icon-display);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.iconMoonTheme {
    display: var(--toggle-moon-icon-display);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.navbar_buttons {
    display: flex;
    align-items: center;
    border: none;
    font-size: 14px;
    font-weight: 500;
}



/* В данном примере я отключил обработку ховера у родителя, поэтому только при наведении на
   дочерний элемент будет срабатывать появление линий сверху и снизу*/

.navbar_buttons .navbarLink {
    cursor: auto;
    pointer-events: none;
    padding: 18px 0;
    border-top: 2px transparent solid;
    border-bottom: 2px transparent solid;
    margin-right: 50px;
    text-decoration: none;
    color: var(--navbar-navbar-buttons-color);
    transition: all 300ms ease-in;
}

.navbar_buttons span {
    pointer-events: auto;
    cursor: pointer;
}

.navbar_buttons .navbarLink:hover, .navbar .active_link {
    color: var(--navbar-navbar-buttons-active-color);
    padding: 2px 0;
    /*background-color: black;*/
    /*border-top: 2px #d0e8ff solid;*/
    /*border-bottom: 2px #d0e8ff solid;*/
}


.social_webs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 0;
}

.social_webs .whatsapp {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.social_webs .inst {
    cursor: pointer;
}

.inst *, .whatsapp * {
    fill: #848484;
    transition: fill 200ms ease;
}

.inst:hover *, .whatsapp:hover * {
    fill: var(--navbar-buttons-social-hover);
}

.burger_block {
    position: relative;
    width: 40px;
    height: 40px;
    background: transparent;
}

.burger {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    cursor: pointer;
    margin: auto 0 auto auto;
}

.burgerFirstLine,
.burgerSecondLine,
.burgerThirdLine {
    stroke: var(--navbar-buttons-burger-color);
    transition: all 200ms ease-in-out;
}

.burgerFirstLine {
    transform-origin: 7% 20%;
}

.burgerThirdLine {
    transform-origin: 7% 80%;
}

.burger_opened .burgerFirstLine {
    transform: rotate(45deg);
}

.burger_opened .burgerThirdLine {
    transform: rotate(-45deg);
}

.burger_opened .burgerSecondLine {
    opacity: 0;
}

@media (max-width: 1040px) {

    .navbar, .shadow_nav {
        height: 70px;
    }

    .navbar .menu {
    }

    .logo {
        width: 50px;
        height: 50px;
    }

    .logo_text span {
        font-size: 11px;
    }

    .navbar_buttons .navbarLink {
        margin-right: 40px;
        white-space: nowrap;
    }

    .social_webs .inst,
    .social_webs .whatsapp {
        height: 22px;
    }
}

@media (max-width: 900px) {
    .burger {
        display: flex;
    }
    
    .changeThemeToggle {
        margin-right: 0 !important;
        margin: 2px 0 6px 0;
    }

    .navbar_buttons {
        border-top: 1px rgba(0, 0, 0, 0.1) solid;
        position: fixed;
        flex-direction: column;
        align-items: center;
        padding: 15px 30px;
        top: 70px;
        right: -250px;
        z-index: 11;
        background-color: var(--navbar-buttons-phone-bg);
        height: 100vh;
        transition: all 300ms ease-in-out;
    }

    .navbar_buttons_opened {
        right: 0;
    }


    .shadow_opened {
        z-index: 9;
        background-color: rgba(0, 0, 0, 0.5);
        top: 70px;
        left: 0;
        width: 100%;
        height: 1000px;
    }

    .navbar_buttons .navbarLink {
        border-top: 1px transparent solid;
        border-bottom: 1px transparent solid;
        font-size: 16px;
        margin: 5px 0;
        padding: 2px 0;
    }

    .social_webs {
        margin: 6px 0;
    }

    .inst, .whatsapp {
        height: 22px;
    }

    .navbar .active_link, .navbar_buttons .navbarLink:hover {
        /*border-top: 1px #d0e8ff solid;*/
        /*border-bottom: 1px #d0e8ff solid;*/
    }
}


@media (max-width: 500px) {
    .navbar, .shadow_nav {
        height: 65px;
    }
    .logo {
        height: 80px;
    }

    .shadow_opened {
        top: 65px;
    }

    .navbar_buttons {
        top: 65px;
    }

    .navbar_buttons .navbarLink {
        padding: 2px 0;
        margin: 5px 0;
    }
}

