.frame {
    position: relative;
    width: calc((100% / 3) - 10px);
    height: 0;
    overflow: hidden !important;
    padding-bottom: 23%;
    margin-bottom: 15px;
    border-radius: 5px !important;
    cursor: pointer;
    flex-shrink: 0;
    z-index: 9;
    transition: all 400ms ease-in-out;        /* When the spec is finished */
}

.project {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 12px 7px var(--mini-project-shadow);
}

.projectPhoto {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 5px !important;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    transition: filter 800ms ease;
    animation: appear 1s ease-in-out;
    -webkit-animation-fill-mode: forwards !important; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards !important;    /* FF 5+ */
    -o-animation-fill-mode: forwards !important;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards !important;     /* IE 10+ */
    animation-fill-mode: forwards !important;         /* When the spec is finished */
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.projectPhotoLoaded {
    opacity: 1;
}

.insetShadow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    border-radius: 5px;
    /*box-shadow: inset 0 0 15px 10px rgba(0, 0, 0, 0.25);*/
}

.frame:hover .projectPhoto{
    filter: brightness(0.6);
    /*transform: scale(1.02);*/
    /*animation: preview 1.8s linear;*/
}


.project span {
    white-space: nowrap;
    font-size: 26px;
    font-family: "Nunito", sans-serif;
    font-weight: 300;
    position: absolute;
    z-index: 3;
    top: 8%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    opacity: 0;
    transition: opacity 800ms ease;
}


.frame:hover .project span {
    opacity: 1;
    /*animation: previewSpan 1.8s linear;*/
}

.loadBG {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    animation: logoBlink 2s infinite ease-in-out;
}

.loadBG span {
    position: absolute;
    z-index: 30;
}

.loaderLogo {
    width: 20%;
}

.params {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 13px;
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
    width: 100%;
}
.param * {
    font-family: "Nunito", sans-serif;
    font-weight: 300;
    color: white;
    z-index: 3;
    opacity: 0;
    font-size: 18px;
    transition: all 800ms ease;
}

.param span {
    margin-left: 7px;
}

.frame:hover .param span {
    opacity: 0.6;
}

.frame:hover .param * {
    stroke: rgba(255, 255, 255, 0.6);
    opacity: 1;
}

.param {
    display: flex;
    align-items: center;
}

.lineBoxes {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    overflow: hidden;
    z-index: 20;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 800ms ease;
}

.frame:hover .lineBoxes {
    opacity: 1;
}

@keyframes previewSpan {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes preview {
    0% {
        transform: scale(1);
        background-color: rgba(1, 1, 1, 0);
    }
    50% {
        transform: scale(1.03);
        background-color: rgba(1, 1, 1, 0.7);
    }
    100% {
        transform: scale(1);
        background-color: rgba(1, 1, 1, 0);
    }
}


@media (max-width: 1040px) {
    .frame {
        width: calc((100% / 2) - 7px);
        padding-bottom: 35%;
        margin-bottom: 14px;
    }
}

@media (max-width: 500px) {
    .frame {
        width: 100%;
        padding-bottom: 65%;
    }
    .projectPhoto{
        filter: brightness(0.5);
    }
    .project span {
        opacity: 1;
    }
    .param * {
        opacity: 1;
        font-size: 16px;
    }

    .params {
        margin-bottom: 8px;
    }

    .lineBoxes {
        display: none;
    }

    .frame:hover .projectPhoto {
        filter: brightness(0.5);
    }
}

@keyframes logoBlink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

