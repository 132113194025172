/** {*/
/*}*/

.fullContainer {
    padding-top: 40px;
    background-color: var(--other-projects-bg);
    transition: all 500ms ease-out;
}

.container {
    position: relative;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    background-color: var(--other-projects-bg);
    transition: all 500ms ease-out;
}

.otherProjectsBlock {
    width: 100%;
}

.otherProjectsTitle {
    display: block;
    font-size: 34px;
    color: var(--about-us-text-color);
    font-family: "Nunito", sans-serif;;
    font-weight: 400;
    transition: all 500ms ease-out;
}

.otherProjects {
    display: flex;
    margin-top: 15px;
    width: 100%;
    overflow-x: hidden;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: auto;
}

.buttonBlock {
    display: flex;
    justify-content: flex-end;
}

.linkToAllProjects {
    text-decoration: none;
}

.buttonAllProjects {
    cursor: pointer;
    padding: 10px 60px;
    background: var(--other-projects-button-bg);
    box-shadow: var(--other-projects-button-shadow);
    color: var(--other-projects-button-color);;
    border: none;
    font-size: 17px;
    border-radius: 8px;
    font-weight: 300;
    font-family: "Nunito", sans-serif;;
    transition: all 300ms ease-in-out;
}

.buttonAllProjects:hover {
    background-color: var(--other-projects-button-bg-hover);
    color: var(--other-projects-button-color-hover);;
}

.swipe {
    position: absolute;
    top: 10px;
    right: 0;
    height: 30px;
    z-index: 4;
}

.swipeIcon {
    height: 100%;
}

.iconSwipe {
    width: 72px !important;
    height: 35px !important;
}


.iconSwipe .hand {
    fill: #4a4a4a;
    transform-origin: 50% 100%;
    animation: hand-swipe 3.5s infinite ease;
}
.iconSwipe .left, .iconSwipe .right {
    fill: #4a4a4a;
    opacity: 0;
}
.iconSwipe .right { animation: arrow-right 3.5s infinite ease; }
.iconSwipe .left { animation: arrow-left 3.5s infinite ease; }

@keyframes hand-swipe {
    5% { transform: translateX(3px) rotate(6deg); }
    25% { transform: translateX(0px) rotate(0deg); }
    28% { transform: translateX(0px) rotate(0deg); }
    33% { transform: translateX(-3px) rotate(-6deg); }
    53% { transform: translateX(0px) rotate(0deg); }
    100% { transform: translateX(0px) rotate(0deg); }
}

@keyframes arrow-right {
    7% { transform: translateX(8px); opacity: 1; }
    17% { transform: translateX(10px); opacity: 0; }
    18%, 100% { transform: translateX(0); opacity: 0; }
}

@keyframes arrow-left {
    0%, 30% { transform: translateX(0); opacity: 0; }
    37% { transform: translateX(-8px); opacity: 1; }
    47% { transform: translateX(-10px); opacity: 0; }
    48%, 100% { transform: translateX(0); opacity: 0; }
}

.path {
    position: absolute;
    width: 12px;
    height: 12px;
    background: rgba(81,119,177, 0.5);
    border-radius: 40px;
    top: -7px;
    left: 23px;
    visibility: hidden;
    animation: swipe-dot 2s 1.05s infinite;
}
/*http://www.asiteaboutnothing.net/c_decode-url.html*/
/*    background: url("data:image/svg+xml,") no-repeat;
*/
.handIcon {
    position: relative;
    background: url("data:image/svg+xml,%3Csvg%20id%3D%22Capa_1%22%20enable-background%3D%22new%200%200%20512%20512%22%20viewBox%3D%220%200%20512%20512%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20fill%3D%22%232F2F2F%22%20d%3D%22m368.226%20168.339c-9.747%200-18.589%203.945-25.019%2010.32-6.074-10.924-17.733-18.336-31.094-18.336-9.748%200-18.589%203.945-25.019%2010.319-6.074-10.924-17.733-18.336-31.094-18.336-19.606%200-35.557%2015.95-35.557%2035.556v68.138c0%204.142%203.358%207.5%207.5%207.5s7.5-3.358%207.5-7.5v-68.137c0-11.334%209.222-20.556%2020.557-20.556s20.557%209.222%2020.557%2020.556v76.153c0%204.142%203.358%207.5%207.5%207.5s7.5-3.358%207.5-7.5v-68.137c0-11.334%209.222-20.556%2020.556-20.556%2011.335%200%2020.557%209.222%2020.557%2020.556v76.153c0%204.142%203.358%207.5%207.5%207.5s7.5-3.358%207.5-7.5v-68.137c0-11.334%209.222-20.556%2020.557-20.556%2011.334%200%2020.556%209.222%2020.556%2020.556v160.323c-.001%2073.216-59.567%20132.782-132.783%20132.782s-132.782-59.566-132.782-132.782v-92.186c0-22.385%2018.211-40.597%2040.597-40.597h.516v96.709c0%204.142%203.358%207.5%207.5%207.5s7.5-3.358%207.5-7.5v-292.587c0-11.335%209.222-20.557%2020.557-20.557%2011.334%200%2020.556%209.222%2020.556%2020.557v100.202c0%204.142%203.358%207.5%207.5%207.5s7.5-3.358%207.5-7.5v-100.202c-.001-19.606-15.951-35.557-35.557-35.557s-35.557%2015.951-35.557%2035.557v180.879h-.516c-30.656%200-55.597%2024.94-55.597%2055.597v92.186c.001%2081.486%2066.296%20147.781%20147.783%20147.781s147.782-66.295%20147.782-147.782v-160.323c0-19.605-15.95-35.556-35.556-35.556z%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E") no-repeat;
    background-position: center;
    width: 30px;
    height: 30px;
    transform-origin: 52% 62%;
    animation: swipe-hand 2s 0.5s infinite;
}

@keyframes swipe-hand {
    25% { transform: translate(20px) rotate(30deg); }
    50% { transform: translate(-30px) rotate(-15deg); }
    100% { transform: translate(0px) rotate(0); }
}

@keyframes swipe-dot {
    12% {
        visibility: visible;
        width: 30px;
    }
    25% {
        visibility: visible;
        transform: translate(-55px);
        width: 20px;
    }
    26% { visibility: hidden; }
}

@media (max-width: 1040px) {
    .otherProjectsTitle {
        font-size: 30px;
        margin-top: 30px;
    }

}
@media (max-width: 730px) {
    .otherProjectsTitle {
        font-size: 28px;
        margin-top: 20px;
    }
}

@media (max-width: 500px) {
    .otherProjectsTitle {
        font-size: 24px;
        margin-top: 15px;
    }

    .swipe {
        top: 5px;
        right: 5px;
    }
}









/*.triangle_down {*/
/*    width: auto;*/
/*    height: auto;*/
/*    background-color: #999999;*/
/*}*/
/*.triangle_down .iss {*/
/*    stroke-dasharray: 690;*/
/*    stroke-dashoffset: 690;*/
/*    transition: stroke-dashoffset 1000ms linear;*/
/*}*/

/*.triangle_down .iss2 {*/
/*    stroke-dasharray: 690;*/
/*    stroke-dashoffset: 690;  !* насколько отодвигаем назад один кусок рамки*!*/
/*    transition: 900ms stroke-dashoffset 1000ms linear;*/
/*}*/

/*.triangle_down:hover .iss, .triangle_down:hover .iss2 {*/
/*    stroke-dashoffset: 0;*/
/*    !*animation: dash 1s ease-in;*!*/
/*    !*animation: dash 1s linear alternate infinite;*!*/
/*}*/



/*@keyframes pulse {*/
/*    from {*/
/*        stroke-dashoffset: 822;*/
/*    }*/
/*    to {*/
/*        stroke-dashoffset: 0;*/
/*    }*/
/*}*/