.footer {
    width: 100%;
    height: auto;
    transition: all 500ms ease-out;
    border-top: var(--footer-border-top);
    background: var(--footer-bg);
    box-shadow: var(--footer-box-shadow);
}

.container {
    margin: 0 auto;
    padding: 50px 20px 20px 20px;
    max-width: 1340px;
    font-family: "Nunito", sans-serif;
}

.information {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoAndText {
    display: flex;
    align-items: center;
}

.logo {
    width: 70px;
    height: 70px;
}

.logoText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    font-size: 15px;
    font-family: "Noah Text", sans-serif;
    font-weight: 700;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.logoText span:nth-child(odd) {
    color: #2358A4;
}

.logoText span:nth-child(even) {
    color: #52B26E;
}

.links {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cow {
    display: flex;
    font-size: 15px;
    flex-direction: column;
}

.cow a {
    transition: all 500ms ease-out;
    color: var(--navbar-navbar-buttons-color);
    text-decoration: none;
}

.cow a:hover {
    color: var(--navbar-navbar-buttons-active-color);
}

.companyInfo {
    font-size: 15px;
    color: var(--navbar-navbar-buttons-active-color);
    font-family: "Noah Text", sans-serif;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.companyInfo a {
    text-decoration: underline;
    color: var(--navbar-navbar-buttons-active-color);
    transition: all 300ms ease-in-out;
    max-width: 140px;
    margin-left: auto;
}

.companyInfo a:hover {
    opacity: 0.8;
}

.rikuName {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    font-size: 14px;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    color: var(--navbar-navbar-buttons-active-color);
}

@media (max-width: 1040px) {
    .logo {
        width: 60px;
        height: 60px;
    }

    .logoText {
        font-size: 14px;
    }

    .cow {
        font-size: 12px;
    }

    .cow a {
        padding: 1px 0;
    }

    .companyInfo {
        font-size: 14px;
    }
    .rikuName {
        font-size: 12px;
    }
}

@media (max-width: 730px) {
    .container {
        padding: 10px 20px;
    }
    .logo {
        width: 50px;
        height: 50px;
    }

    .logoText {
        font-size: 11px;
    }

    .cow {
        font-size: 12px;
    }

    .cow a {
        padding: 1px 0;
    }

    .companyInfo span {
        font-size: 12px;
        padding: 2px 0;
    }

    .rikuName {
        font-size: 10px;
    }
}

@media (max-width: 560px) {
    .companyInfo a {
        margin-left: 0;
    }
    .information {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .companyInfo {
        text-align: left;
    }

    .cow {
        text-align: left;
        margin-bottom: 10px;
    }

}
