.container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
    font-family: "Noah Text", sans-serif;
}

.title {
    display: block;
    text-align: center;
    margin-top: 25px;
    font-size: 44px;
    font-family: "Noah Text", sans-serif;
    font-weight: 500;
}

.text {
    display: block;
    font-size: 22px;
    margin: 20px 0;
}

.image {
    width: 50%;
    height: auto;
    margin: 20px;
    border-radius: 8px;
}

.blogHR {
    width: 180px;
    align: center;
    margin: 0 auto;
    height: 1px;
    background-color: black;
    border: none;
}



@media (max-width: 990px) {
    .title {
        font-size: 36px;
    }

    .text {
        font-size: 20px;
    }
    .image {
        margin: 20px 10px;
        border-radius: 6px;
    }
}

@media (max-width: 760px) {
    .title {
        font-size: 36px;
    }
    .text {
        font-size: 18px;
    }
    .image {
        width: 100%;
        margin: 20px 0;
        border-radius: 4px;
    }
}