.skeletonBlock {
    position: relative;
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
    padding-bottom: 50%;
}
.skeleton {
    position: absolute;
    top:0;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 1040px) {
    .skeletonBlock {
        width: calc((100% / 2) - 7px);
        padding-bottom: 35%;
        margin-bottom: 14px;
    }
}

@media (max-width: 500px) {
    .skeletonBlock {
        width: 100%;
        padding-bottom: 65%;
    }
}