.previewBlock {
    position: relative;
    width: 100%;
    font-family: "Nunito", sans-serif;;

}

.previewBlock .previewVideoBlock {
    position: relative;
    line-height: 0; /* убрался отступ под видео */
    width: 100%;
    overflow: hidden;
    /*vertical-align: top;  !* убираем под фотографией странный отступ *!*/
}

.previewVideo {
    filter: brightness(0.9);
    width: 100%;
    /*background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.33) 48.54%, rgba(0, 0, 0, 0.82) 100%), rgba(255, 255, 255, 0);*/
    height: calc(100vh - 80px);
    /*height: 100%;*/
    /*max-height: calc(100vh - 200px);*/
    object-fit: cover;
}

/* делаем абс позиционирование, выставляем отступы от краев */
/* даем размеры в 100% от блока с фотографией, центрируем */
.imageTextContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1340px;
}

.trapezoid {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    border-top: 100vh solid rgba(0, 0, 0, 0.45);
    border-right: 200px solid transparent;
    height: 0;
    width: 55%;
    z-index: 1;
}



.imageTextContainer .titleText {
    position: absolute;
    top: 80px;
    left: 50%;
    width: 800px;
    color: white;
    font-size: 40px;
    font-style: normal;
    font-family: "Nunito", sans-serif;

    font-weight: 500;
    line-height: 120.687%; /* 57.93px */
    letter-spacing: -0.24px;
    transform: translate(-50%, 0);
    z-index: 5;
    text-align: center;
}

.leftBlock {
    position: absolute;
    left: 20px;
    top: 60%;
    transform: translateY(-50%);
    width: 460px;
    color: white;
    z-index: 5;
}

.titleTextLeft {
    font-size: 34px;
    font-weight: 300;
    line-height: 135.687%; /* 65.13px */
    letter-spacing: -0.24px;
    font-family: "Nunito", sans-serif;;
    transition: all 300ms ease-in;
}

.line {
    width: 0;
    margin-top: 20px;
    height: 2px;
    background-color: white;
    animation: 1s line 1s ease-in-out;
    -webkit-animation-fill-mode: forwards !important; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards !important;    /* FF 5+ */
    -o-animation-fill-mode: forwards !important;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards !important;     /* IE 10+ */
    animation-fill-mode: forwards !important;         /* When the spec is finished */
}

.leftBlockText {
    display: inline-block;
    margin-top: 10px;
    color: white;
    font-size: 20px;
    font-weight: 300;
    transform: translateY(-100%);
    opacity: 0;
    animation: 1.5s miniText 1s ease-in-out;
    -webkit-animation-fill-mode: forwards !important; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards !important;    /* FF 5+ */
    -o-animation-fill-mode: forwards !important;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards !important;     /* IE 10+ */
    animation-fill-mode: forwards !important;         /* When the spec is finished */
}

.logoBlock {
    position: absolute;
    right: 20px;
    top: 130px;
    width: 70px;
}

.contactButton {
    cursor: pointer;
    display: block;
    margin-top: 15px;
    background: none;
    opacity: 0;
    transform: translateX(-200%);
    border: white 2px solid;
    border-radius: 12px;
    padding: 10px 30px;
    color: white;
    font-size: 18px;
    transition: all 200ms ease-in;
    animation: 2s  contactButton 1.5s ease-out;
    -webkit-animation-fill-mode: forwards !important; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards !important;    /* FF 5+ */
    -o-animation-fill-mode: forwards !important;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards !important;     /* IE 10+ */
    animation-fill-mode: forwards !important;         /* When the spec is finished */
}

.imageTextContainer a {
    text-decoration: none;
    display: inline-block;
}

.imageTextContainer .contactButton:hover {
    background: white;
    color: black;
}

@keyframes line {
    0% {
        width: 0;
    }
    100% {
        width: 90%;
    }
}

@keyframes contactButton {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes miniText {
    0% {
        transform: translateY(-200%);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 1400px) {
    .previewVideo {
        height: 700px;
    }

    .imageTextContainer {
        overflow: hidden !important;
    }
}

@media (max-width: 1040px) {

    .previewVideo {
        height: 500px;
        max-height: none;
    }


    .imageTextContainer .titleText {
        font-size: 34px;
        top: 50px;
    }

    .leftBlock {
        width: 320px;
    }

    .titleTextLeft {
        font-size: 26px;
    }

    .leftBlockText {
        font-size: 16px;
    }

    .logoBlock {
        width: 60px;
    }
    .imageTextContainer .contactUsBlock {
        width: 250px;
    }
    .contactTitleText {
        font-size: 30px;
    }

    .contactText {
        font-size: 16px;
    }
    .contactButton {
        padding: 8px 18px;
        font-size: 16px;
    }
}

@media (max-width: 730px) {
    .previewVideo {
        height: 450px;
    }
    .imageTextContainer .titleText {
        font-size: 26px;
    }

    .leftBlock {
        width: 230px;
    }

    .titleTextLeft {
        font-size: 18px;
    }

    .leftBlockText {
        font-size: 14px;
    }

    .logoBlock {
        top: 70px;
        width: 50px;
    }
    .imageTextContainer .contactUsBlock {
        width: 230px;
        bottom: 20px;
    }
    .contactTitleText {
        font-size: 24px;
    }

    .contactText {
        font-size: 14px;
    }
    .contactButton {
        padding: 8px 18px;
        font-size: 14px;
    }
}


@media (max-width: 500px) {

    .previewVideo {
        height: 350px;
    }

    .trapezoid {
        border-right: 150px solid transparent;
        width: 60%;

    }

    .line {
        width: 0;
        margin-top: 5px;
        height: 2px;
    }

    .leftBlockText {
        font-size: 12px;
        margin-top: 5px;
    }

    .imageTextContainer .titleText {
        width: 350px;
        top: 40px;
        font-size: 22px;
    }

    .leftBlock {
        top: 60%;
        width: 150px;
    }

    .titleTextLeft {
        font-size: 14px;
    }

    .logoBlock {
        top: 20px;
        width: 40px;
        height: 40px;
    }
    .imageTextContainer .contactUsBlock {
        width: 200px;
        bottom: 20px;
    }
    .contactTitleText {
        font-size: 18px;
    }

    .contactText {
        font-size: 12px;
        margin-top: 5px;
    }
    .contactButton {
        padding: 8px 20px;
        font-size: 12px;
        margin-top: 7px;
    }
}