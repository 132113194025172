.blog {
    display: flex;
    pointer-events: auto;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    width: 300px;
    height: 300px;
    margin: 10px 0;
    overflow: hidden;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
}

.blog:hover .img {
    filter: brightness(0.5);
}

/*.blog:hover .blogTitle {*/
/*    color: #eaddbb;*/
/*}*/

.blog:hover .glass {
    width: 55%;
}


.img {
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 300ms ease-in-out;
}

.glass {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 45%;
    border-radius: 5px 0 0 5px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(6px);
    transition: all 300ms ease-in-out;
}

.textBlock {
    pointer-events: none;
    position: absolute;
    width: calc(100% - 30px);
    bottom: 15px;
    left: 15px;
    font-size: 16px;
}

.blogTitle {
    display: block;
    font-family: "Noah Head", sans-serif;
    /*color: #44546b;*/
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
    /*background-color: rgba(168, 161, 153, 0.46);*/
    /*backdrop-filter: blur(3px);*/
    /*border-radius: 6px;*/
    /*padding: 4px 6px;*/
    transition: all 300ms ease-in-out;
}

.blogText {
    display: block;
    color: #ffffff;
    font-size: 16px;
}

.date {
    pointer-events: none;
    position: absolute;
    display: block;
    top: 15px;
    left: 15px;
    color: #656565;
    transition: all 300ms ease-in-out;
}



@media (max-width: 670px) {
    .blog {
        width: 320px;
        height: 320px;
    }

    .img {
        filter: brightness(0.5);
    }
}
