.arrow {
    rotate: 0deg;
    width: 25px;
    height: 25px;
    transition: rotate 700ms ease;
    -webkit-transition: all 700ms ease;
}

.arrow * {
    stroke: var(--our-projects-text-color);
}

.arrowOpened {
    -webkit-transform: rotate(-180deg); /* Градусы поворота можно изменить по необходимости */
    transform: rotate(-180deg); /* Градусы поворота можно изменить по необходимости */
    -webkit-backface-visibility: hidden;
}

.sortButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--our-projects-text-color);
    height: 40px;
    border-radius: 8px;
    background: var(--our-projects-sortButton-bg);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    font-size: 17px;
    font-family: "Noah Text", sans-serif;
    font-weight: 300;
    transition: background 300ms ease;
    padding: 0 20px;
}

.sortButton span {
    display: block;
    margin-right: 3px;
}

.sortButtonSelected, .sortButton:hover {
    background: var(--our-projects-sortButton-selected-bg);
}

@media (max-width: 1040px) {

}

@media (max-width: 730px) {
    .sortButton {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .arrow {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 630px) {

}